export const data = {
  hydrafacial: {
    title: "Hydrafacial",
    col: 4,
    data: [
      {
        title: "Hydrafacial Signature",
        desc: "Brinda una limpieza profunda, extrae impurezas e hidrata la piel, utilizando un potencializador de antioxidantes, péptidos y ácido hialurónico.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Hydrafacial Deluxe",
        desc: "La extracción prolongada y la terapia con luces LED, es ideal para pieles grasas y/o congestionadas.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Hydrafacial Deluxe-DERMABUILDER",
        desc: "Ayuda a suavizar la apariencia de líneas finas, arrugas y mejora la elasticidad de la piel.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Hydrafacial Deluxe-BRITENOL",
        desc: "Ilumina y equilibra el tono de la piel mientras minimiza la aparición de manchas oscuras y manchas solares.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Hydrafacial Deluxe-REGEN GF",
        desc: "Los péptidos regenerativos imitan los factores de crecimiento para aumentar la producción de colágeno y elastina. Su innovadora tecnología M-007 asegura una absorción más profunda y efectiva.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Hydrafacial Deluxe-HYDRAGLUCAN (NASSIFMD) ",
        desc: "La mezcla patentada de ingredientes va a hidratar, reparar, rejuvenecer y proteger la piel. Además, restaura la hinchazón, luminosidad y trata los signos de envejecimiento.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Hydrafacial Platinum",
        desc: "Comienza con un procedimiento de desintoxicación con terapia linfática, continúa con el tratamiento de lujo para limpiar, extraer impurezas e hidratar profundamente la piel, mientras se atienden problemas específicos con un vial especial de tu elección, se incluye terapia de luces LED.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Dranaje Linfático Facial",
        desc: "Elimina la acumulación de toxinas del interior del organismo para conseguir una piel sana, ayuda a desintoxicar la piel, disminuye la inflamación, elimina la acumulación no deseada y Optimiza la salud de la piel en tu rostro.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Hydraderma (Hydrafacial + Células Madre)",
        desc: "La combinación de hydrafacial para una limpieza profunda y la aplicación de células madre le dan al rostro una apariencia luminosa, hidratada y radiante, este tratamiento ayudará a una bio-estimulación de la piel, ayudará a unificar el tono y a prevenir las líneas de expresión.",
        session: "1 sesión cada 20 días",
        time: "Tiempo estimado del procedimiento 90 min",
      }
    ],
  },
  radiofrecuencia: {
    title: "Radiofrecuencia",
    col: 4,
    data: [
      {
        title:
          "TriBella rejuvenecimiento facial integral (estiramiento facial, eliminación de manchas y rejuvenecimiento total del rostro)",
        desc: "Nuestro tratamiento diamante, realiza tres tratamientos en uno, es decir, en la misma sesión se aplica luz pulsada intensa, una radiofrecuencia multipolar con pulso magnético y una radiofrecuencia nanofraccionada, beneficiando, por tanto, de la sinergia de los tres tratamientos. Lograremos en una sola sesión, tratar manchas, poros dilatados, arrugas, marcas, y rojeces, todos los signos de envejecimiento en un solo tratamiento. Aporta intensa luminosidad a la piel y un potente efecto tensor.",
        pack: "Paquete de 3 sesiones",
        session: "1 sesión por mes",
        time: "Tiempo estimado del procedimiento 120 min",
      },
      {
        title: "Perfilado facial y modelado del rostro",
        desc: "Nuestra tecnología con radiofrecuencia es la única aparatología de radiofrecuencia que combina radiofrecuencia multipolar, campos electromagnéticos pulsados y succión. Esto permite brindar tratamientos 100% seguros y sin dolor para todos los tipos de piel (sin tiempo de recuperación), con resultados inmediatos y a largo plazo en rostro, su efectividad permite comprobar con muy pocas sesiones un perfilado facial sin cirugía.",
        pack: "Paquete de 6 sesiones",
        session: "1 sesión por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Lifting cuello y escote ",
        desc: "Nuestra tecnología con radiofrecuencia es la única aparatología de radiofrecuencia que combina radiofrecuencia multipolar, campos electromagnéticos pulsados y succión, con retroalimentación termal en tiempo real. Esto permite brindar tratamientos 100% seguros y sin dolor para todos los tipos de piel (sin tiempo de recuperación), con resultados inmediatos y a largo plazo en cuello y escote, su efectividad permite comprobar con muy pocas sesiones un cuello y escote lisos y rejuvenecidos sin cirugía.",
        pack: "Paquete de 6 sesiones",
        session: "1 sesión por semana",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Rejuvenecimiento de ojos",
        desc: "Nuestra tecnología con radiofrecuencia especializada y controlada para esa área tan delicada como son los parpados nos permite, estimular la producción natural de colágeno y desencadena la reparación.",
        pack: "Paquete de 6 sesiones",
        session: "1 sesión por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
    ],
  },
  luzpulsada: {
    title: "Luz pulsada",
    col: 4,
    data: [
      {
        title: "Foto rejuvenecimiento con luz pulsada intensa (IPL)",
        desc: "Exclusivo tratamiento que ayuda a la producción de colágeno de manera gradual y progresiva, pudiéndose tratar la piel de cara, cuello, escote, manos e incluso zonas delicadas como patas de gallo y código de barras alrededor de los labios. Nuestro sistema con SmartPulse™ permite en menos sesiones obtener grandes resultados.",
        pack: "Paquete de 5 sesiones",
        session:
          "1 sesión cada tercera semana, Mantenimiento 2 a 4 sesiones por año",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Eliminación de manchas y melasma ",
        desc: "Excelente sistema que disminuye la melanina que se acumula en exceso en la piel disminuyendo las manchas producidas por el sol y por el paso del tiempo en rostro, cuello, escote y manos. Permite en menos sesiones obtener grandes resultados.",
        pack: "Paquete de 5 sesiones",
        session:
          "1 sesión cada tercera semana, Mantenimiento de 2 a 4 sesiones por año",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Rosácea y telangiectasia facial",
        desc: "Rosácea, puntos rubí y telangiectasias son padecimientos vasculares que resultan antiestéticos, elimina en menos tiempo con nuestro sistema con SmartPulse™ esas molestas zonas rojas del rostro.",
        pack: "Paquete de 5 sesiones",
        session:
          "1 sesión cada tercera semana, Mantenimiento de 2 a 4 sesiones por año",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Disminución de efélides (pecas) en rostro ",
        desc: "Efectivo tratamiento con IPL que emite un haz de luz específico para generar una foto termólisis selectiva. Permite atenuar esas molestas manchas que aparecen debido a un aumento de melanina en la epidermis.",
        pack: "Paquete de 5 sesiones",
        session:
          "1 sesión cada tercera semana, Mantenimiento de 2 a 4 sesiones por año",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title:
          "TriBella rejuvenecimiento facial integral (estiramiento facial, eliminación de manchas y rejuvenecimiento total del rostro)",
        desc: "Nuestro tratamiento diamante, realiza tres tratamientos en uno, es decir, en la misma sesión se aplica luz pulsada intensa, una radiofrecuencia multipolar con pulso magnético y una radiofrecuencia nanofraccionada, beneficiando, por tanto, de la sinergia de los tres tratamientos. Lograremos en una sola sesión, tratar manchas, poros dilatados, arrugas, marcas, y rojeces, todos los signos de envejecimiento en un solo tratamiento. Aporta intensa luminosidad a la piel y un potente efecto tensor.",
        pack: "Paquete de 3 sesiones",
        session: "1 sesión por mes",
        time: "Tiempo estimado del procedimiento 120 min",
      },
    ],
  },
  radiofrecuencianano: {
    title: "Radiofrecuencia Nanofraccionada",
    col: 4,
    data: [
      {
        title: "Reducción de secuelas de acné.",
        desc: "Es una solución no quirúrgica para el rejuvenecimiento de la piel mediante resurfacing que corrige los signos de daños en la piel y mejora el aspecto de las marcas de acné. Elimina marcas y cicatrices del acné a través de nuestra tecnología de radiofrecuencia nanofraccionada.",
        pack: "Paquete de 3 sesiones",
        session: "1 sesión por mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Tratamiento para rosácea ",
        desc: "Para los pacientes que experimentan rosácea o cicatrización en la fase sintomática como resultado de la rosácea, un plan de tratamiento personalizado con tecnología de tratamiento avanzada puede ayudar a impulsar la renovación de las células de la piel y mejorar la textura de la piel. Con tecnología de radiofrecuencia nanofraccionada, utiliza un aplicador con pequeños pines para crear heridas micro dérmicas sin afectar el tejido circundante y que desencadenan el proceso de curación natural de la piel.",
        pack: "Paquete de 3 sesiones",
        session: "1 sesión por mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Eliminación de líneas de expresión",
        desc: "Es un tratamiento innovador de rejuvenecimiento, cuya eficacia impresionante se debe al uso de la radiofrecuencia nanofraccionada, que es aplicado sobre la piel mediante 160 pines o micro agujas, cada una de ellas generando un estímulo térmico que generará la producción de nuevas fibras de colágeno y elastina y obteniendo como resultado el rejuvenecimiento de la piel desde la primera sesión.",
        pack: "Paquete de 3 sesiones",
        session: "1 sesión por mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Eliminación de manchas y pigmentación localizada ",
        desc: "Cada piel tiene diferentes necesidades. Algunas tienen más tendencia a las manchas, nuestro sistema con radiofrecuencia nanofraccionada, produce ablación que consiste en una renovación del tejido por vaporización tratando tanto la epidermis como la dermis y se consigue eliminar las lesiones que pueda haber como manchas.",
        pack: "Paquete de 3 sesiones",
        session: "1 sesión por mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Reducción de poro y refinamiento de la textura de la piel ",
        desc: "El único sistema de alisamiento y mejoramiento de la piel permite la ablación y la coagulación de la zona de la epidermis y la dermis de manera fraccional, lo que resulta en un rejuvenecimiento y refinamiento del poro logrando recuperar una piel lisa y bella.",
        pack: "Paquete de 3 sesiones",
        session: "1 sesión por mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title:
          "TriBella rejuvenecimiento facial integral (estiramiento facial, eliminación de manchas y rejuvenecimiento total del rostro) ",
        desc: "Nuestro tratamiento diamante, realiza tres tratamientos en uno, es decir, en la misma sesión se aplica luz pulsada intensa, una radiofrecuencia multipolar con pulso magnético y una radiofrecuencia nanofraccionada, beneficiando, por tanto, de la sinergia de los tres tratamientos. Lograremos en una sola sesión, tratar manchas, poros dilatados, arrugas, marcas, y rojeces, todos los signos de envejecimiento en un solo tratamiento. Aporta intensa luminosidad a la piel y un potente efecto tensor.",
        pack: "Paquete de 3 sesiones",
        session: "1 sesión por mes",
        time: "Tiempo estimado del procedimiento 120 min",
      },
      {
        title: "Disminución de cicatrices y regeneración celular",
        desc: "Tratamiento no invasivo y efectivo para la regeneración celular. Con nuestra tecnología de radiofrecuencia nanofraccionada podemos resolver con mayor eficacia los daños cutáneos de leves a severos. Debido a su efecto térmico, permite el realineamiento de las fibras colágenas, mejorando así el aspecto cicatricial.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
    ],
  },
  mesoject: {
    title: "MESOJECT GUN",
    col: 4,
    data: [
      {
        title: "MESOTERAPIA PARA PAPADA, ANTIEDAD Y MANCHAS",
        desc: "Tratamiento rejuvenecedor donde podemos aportar todos los nutrientes que necesita la piel. Nuestro nuevo dispositivo es eficiente, accesible, seguro y simple para administrar y aumentar la absorción de ingredientes activos en las capas más profundas de la piel mediante energía de electroporación (lo que conoces como mesoterapia). Utiliza una herramienta especial de microchip para introducción de ingredientes activos con la máxima eficacia y la mínima invasividad. \n\nOfrecemos protocolos específicos para trabajar las problemáticas más comunes de la piel como papada, envejecimiento e irritación, pigmentación y acné, resultados visibles desde las primeras sesiones.",
        pack: "Hasta 10 sesiones (Dependiendo valoración médica)",
        session: "5 sesiones semanales",
        time: "Tiempo estimado del procedimiento: 60 min.",
      },
    ]
  },
  DEPILACIÓN: {
    title: "DEPILACIÓN LÁSER",
    col: 4,
    data: [
      {
        title: "DEPILACIÓN FACIAL DEFINITIVA",
        desc: "Laser Crystal 3D es la tecnología más avanzada, segura y eficaz en depilación láser. La eliminación de vello es realizada por la transmisión de la energía del láser al folículo piloso. Las áreas de depilación facial incluyen entrecejo, frente, patillas, mejillas, bigote y mentón.",
        session: "1 sesión cada mes",
        time: "Tiempo estimado dependiendo de la zona a tratar"
      }
    ]
  },
  ultrasonido: {
    title: "ULTRASONIDO",
    col: 4,
    data: [
      {
        title: "ULTRASONIDO EFECTO LIFTING",
        desc: "Proceso, no invasivo e indoloro, que estimula el drenaje linfático, atenúa y elimina manchas de la piel y líneas de expresión. Reafirma el rostro por medio de la producción de colágeno, disminuyendo ojeras, acné y puntos negros; ideal para desinflamar la piel y reducir papada mejorando la circulación sanguínea",
        pack: "6 a 10 sesiones (Dependiendo valoración médica)",
        session: "1 sesión cada 3 semanas",
        time: "Tiempo estimado del procedimiento: 30-40 min",
      },
      {
        title: "REDUCCIÓN DE BOLSAS EN LOS OJOS",
        desc: "Nuestra tecnología con ultrasonido desinflama la piel, elimina toxinas, eleva las cejas y minimiza las líneas de expresión. Activa la producción de colágeno.",
        pack: "6 a 10 sesiones (Dependiendo valoración médica)",
        session: "1 sesión cada 3 semanas",
        time: "Tiempo estimado del procedimiento: 30 min.",
      },
      {
        title: "HIDRATACIÓN Y SECUELAS DE ACNÉ",
        desc: "El ultrasonido aumenta la oxigenación de las células, mejora la hidratación de la piel y activa la microcirculación;  eliminando acné y cicatrices.",
        pack: "Número de sesiones: Personalizadas, a partir de una valoración médica.",
        session: "1 sesión cada 3 semanas",
        time: "Tiempo estimado del procedimiento: 30-40 min.",
      },
    ]
  },
  fototerapia: {
    title: "FOTOTERAPIA",
    col: 4,
    data: [
      {
        title: "FOTOTERAPIA CON MÁSCARA LED",
        desc: "La fototerapia es una técnica novedosa y nada invasiva en la medicina estética que ayuda a combatir algunas afecciones de la piel tales como acné, hiperpigmentación, arrugas y signos del envejecimiento.",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 30 min"
      }
    ]
  },
  carbo: {
    title: "CARBOXITERAPIA",
    col: 4,
    data: [
      {
        title: "CARBOXITERAPIA FACIAL",
        desc: "Tratamiento estético no quirúrgico que favorece la oxigenación de las células faciales mediante la infiltración de CO2 (dióxido de carbono). Este gas, que nuestro cuerpo expulsa de forma natural a través de la respiración, ayuda a mejorar la circulación sanguínea al conseguir que los tejidos subcutáneos se relajen provocando la tensión de la piel y se produzca una dilatación vascular, acelera el metabolismo y crea la reproducción de células de colágeno y elastina. \n\nLa carboxiterapia está indicada en rostro para ojeras, líneas de expresión, disminución de papada y tensión de la piel.",
        pack: "Hasta 10 sesiones (Dependiendo valoración médica)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento: 20-30 min.",
      },
    ]
  },
  
};
