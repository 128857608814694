import styled from 'styled-components';

export const Button = styled.div`
   position: fixed; 
   right: 30px;
   top: 50px;
   height: 20px;
   font-size: 3rem;
   z-index: 99;
   cursor: pointer;
   color: rgb(96, 173, 191) ;
`