import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/mesoject-gun.png";
import maquina from "Pages/Images-Tecnology/mesoject-gun-machine.png";

const Mesoject = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" style={{}} />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" />
          </Col>
          <Col
            xs={12}
            style={{
              marginTop: "3em",
            }}
          >
            <div
              style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {/* <h1>MESOJECT GUN</h1> */}
            </div>
          </Col>
          <Row className="inner-tech-text">
            <Col
              className="inner-tech-col"
              xs={{ order: 2, size: 12 }}
              md={{ order: 1, size: 4 }}
            >
              <h5>
                <b>MESOJECT GUN</b>
              </h5>
              <p>
                TKN MesojectGun utiliza la innovadora tecnología EPM;
                combinación de energía de electroporación y administración
                transdérmica, utilizando una herramienta especial de microchip.
              </p>
              <p>
                Permite crear micro canales reversibles en la barrera cutánea
                del estrato córneo, mejorando la penetración de ingredientes
                activos en las diferentes capas y estructuras de la piel.
              </p>
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col
              className="inner-tech-col"
              xs={{ order: 3, size: 12 }}
              md={{ order: 3, size: 4 }}
            >
              <h5><b>Beneficios:</b></h5>
              <ul>
                <li>
                  Técnica indolora, sin marcas ni morados post tratamiento
                </li>
                <li>Posibilidad de tratamientos en zonas muy diversas </li>
                <li>Resultados visibles desde las primeras aplicaciones </li>
                <li>Entrada no invasiva de ingredientes activos </li>
                <li>Incrementa la síntesis de colágeno </li>
                <li>Mejora la microcirculación en la dermis </li>
                <li>Estimula el metabolismo celular </li>
              </ul>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default Mesoject;
