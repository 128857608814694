import React, { useState, useEffect } from "react";
import "Pages/pages.css";
import { Helmet } from "react-helmet";
import { useLocation, useHistory } from "react-router-dom";

import Adipo from "Pages/Technology/adipo";
import Nova from "Pages/Technology/nova";
import Hydrafacial from "Pages/Technology/hydrafacial";
import Venus from "Pages/Technology/venus";
import VenusVersa from "Pages/Technology/venusversa";
import Carboxiterapia from "Pages/Technology/carboxiterapia";
import Ultrasonido from "Pages/Technology/ultrasonido";
import SkinAnalizer from "Pages/Technology/skinanalizer";

import ReactSlidy from "react-slidy";
import "react-slidy/lib/styles.css";
import Sirio from "./Technology/sirio";
import LaserCristal from "./Technology/lasercrystal";
import Mesoject from "./Technology/mesoject";

// const createStyles = (isActive) => ({
//   background: "transparent",
//   border: 0,
//   color: "white",
//   cursor: "pointer",
//   fontSize: "60px",
// });

// const conditionalClass = (isActive) =>
//   isActive ? "active-bullet" : "non-active-bullet";

const Technology = (props) => {
  const [actualSlide, setActualSlide] = useState(0);
  const location = useLocation();
  const history = useHistory();
  const [selectedMachine, setMachine] = useState(0);

  const updateSlide = ({ currentSlide }) => {
    setActualSlide(currentSlide); 

    console.log(currentSlide);

    if(currentSlide >= 0)
      history.push(`#${machines[currentSlide].route}`);
  };
  
  // const SLIDES = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  useEffect(() => {
    let key = location.hash.substring(1);
    const machine = machines.find((m) => m.route === key);

    if(machine){
      const indexMachine = machines.indexOf(machine);

      if(indexMachine !== selectedMachine){
        setMachine(indexMachine)
        updateSlide({currentSlide: indexMachine});
      }
    }
  // eslint-disable-next-line
  }, [location]);

  const machines = [
    {
      title: "LASER CRYSTAL 3D",
      route: "lasercrystal"
    },
    {
      title: "SIRIO",
      route: "sirio"
    },
    {
      title: "Venus Legacy",
      route: "venuslegacy"
    },
    {
      title: "Venus Versa",
      route: "venusversa"
    },
    {
      title: "Hydrafacial",
      route: "hydrafacial"
    },
    {
      title: "OPATRA DOME SKIN ANALIZER",
      route: "skinanalizer"
    },
    {
      title: "Mesoject Gun",
      route: "mesojectgun"
    },
    {
      title: "AdipoShape",
      route: "adiposhape"
    },
    {
      title: "NovaPress Smart",
      route: "novapresssmart"
    },
    {
      title: "Carboxiterapia",
      route: "carboxiterapia"
    },
    {
      title: "ULTRASONIDO",
      route: "uld"
    }
  ];

  return (
    <div className="full-frame tech-container" id="section-md">
      <Helmet>
        <meta charSet="utf-8" />
        <title>La Vie Clinic Technology</title>
        <meta
          name="description"
          content="Conoce la tecnología que La Vie Clinic domina para el cuidado de tu cuerpo y piel."
        />
      </Helmet>
      {/* <div className="Dots-floating">
        {SLIDES.map((_, index) => {
          return (
            <button
              key={index}
              className={conditionalClass(index === actualSlide)}
              style={createStyles(index === actualSlide)}
              onClick={() => updateSlide({ currentSlide: index })}
            >
              &bull;
            </button>
          );
        })}
      </div> */}
      <ReactSlidy
        doAfterSlide={updateSlide}
        slide={actualSlide}
        itemsToPreload={10}
        infiniteLoop
      >
        <LaserCristal />
        <Sirio />
        <Venus />
        <VenusVersa />
        <Hydrafacial />
        <SkinAnalizer />
        <Mesoject />
        <Adipo />
        <Nova />
        <Carboxiterapia />
        <Ultrasonido />
      </ReactSlidy>
    </div>
  );
};

export default Technology;
