import React, { useState } from "react";
import { Helmet } from "react-helmet";

import "Pages/pages.css";
import { Accordion } from "Pages/Partials/Accordion";
import { data } from "data/MECData";

import { Row, Container, Col } from "reactstrap";

import ReactSlidy from "react-slidy";
import "react-slidy/lib/styles.css";

import tiempo from "Landing/Images/tiempo.png";
import images from "Pages/imagesCorporal";

import background from "Pages/Images-Corporal/background.jpg";

const conditionalClass = (isActive, index) =>
  isActive
    ? `carrouselFMV-button-f active-carr-f`
    : `carrouselFMV-button-f non-active-carr-f`;

const MedicinaEsteticaCorporal = (props) => {
  const TITLES = Object.keys(data).map(function (key, index) {
    return data[key].title;
  });
  const SLIDES = Object.keys(data).map(function (key, index) {
    return data[key].data;
  });

  const [actualSlide, setActualSlide] = useState(0);

  const updateSlide = ({ currentSlide }) => {
    setActualSlide(currentSlide);
  };

  return (
    <Container
      id="section-md"
      className="Section-MD"
      style={{ minHeight: "100vh" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>La Vie Clinic Medicina Estética Corporal</title>
        <meta
          name="description"
          content="Conoce la belleza del rejuvenecimiento, nutre y dale firmeza a tu piel, disfruta de un cuerpo moldeado, tonificado y sin imperfecciones."
        />
      </Helmet>
      <div
        className="background-section"
        style={{
          backgroundImage: `url(${background})`,
        }}
      />
      <div className="tiempo-container">
        <img src={tiempo} alt="Top banner" />
      </div>
      <Row className="padding-top-banner-mobile" style={{ minHeight: "100vh", paddingTop: "25vh" }}>
        <Col xs={12} style={{ paddingRight: "7vw", paddingLeft: "7vw" }}>
          <Row>
            <Col xs={12}>
              <h5>¡Reinvéntate con tecnología de última generación!</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5>
                Conoce la belleza del rejuvenecimiento, nutre y dale firmeza a
                tu piel, disfruta de un cuerpo moldeado, tonificado y sin
                imperfecciones.
              </h5>
            </Col>
          </Row>
          <Row
            style={{
              paddingTop: "2em",
              paddingBottom: "2em",
            }}
          >
            {TITLES.map((title, index) => {
              const parts = title.split("\n");
              console.log(parts);
              return (
                <Col
                  xs={6}
                  md={index > 7 ? 6 : 3}
                  key={index}
                  className="custom-col-page"
                >
                  <button
                    key={index}
                    className={conditionalClass(index === actualSlide, index)}
                    onClick={() => updateSlide({ currentSlide: index })}
                    style={{
                      fontSize: index === 6 ? ".9rem" : "",
                      height: "100%"
                    }}
                  >
                    {title.split(/\n/).map((line, index) => index === 0 ? line : <><br/>{line}</>)}
                  </button>
                </Col>
              );
            })}
          </Row>
          <ReactSlidy
            doAfterSlide={updateSlide}
            slide={actualSlide}
            showArrows={false}
          >
            {SLIDES.map((aItem, index) => (
              <Row key={index}>
                <Col xs={12}>
                  <Accordion open={0}>
                    {aItem.map((item, index_n) => {
                      return (
                        <Accordion.Item key={index_n}>
                          <Accordion.Header>{item.title}</Accordion.Header>
                          <Accordion.Body>
                            <Row
                              style={{
                                whiteSpace: "normal",
                              }}
                            >
                              <Col
                                md={4}
                                style={{
                                  backgroundImage: `url(${images[index][index_n]})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  marginTop: "-1em",
                                  marginBottom: "-1em",
                                  marginLeft: "-.25em",
                                  whiteSpace: "normal!important",
                                }}
                                className="image-container-accordion"
                              ></Col>
                              <Col md={8}>
                                <Row>
                                  <Col xs={12}>
                                    <div>
                                      <p>{item.desc}</p>
                                    </div>
                                  </Col>
                                  <Col xs={12}>
                                    <h6 style={{
                                      fontSize: "12px"
                                    }}>
                                      <b>{item.notes}</b>
                                    </h6>
                                  </Col>
                                  <Col xs={12}>
                                    <h6>
                                      <b>{item.pack}</b>
                                    </h6>
                                  </Col>
                                  <Col xs={12}>
                                    <h6>
                                      <b>{item.session}</b>
                                    </h6>
                                  </Col>
                                  <Col xs={12}>
                                    <h6>
                                      <b>{item.time}</b>
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </Col>
              </Row>
            ))}
          </ReactSlidy>
        </Col>
      </Row>
    </Container>
  );
};

export default MedicinaEsteticaCorporal;
