  import "Pages/pages.css";

  import { Row, Col } from "reactstrap";

  import logo from "Pages/Images-Tecnology/dome-logo.png";
  import maquina from "Pages/Images-Tecnology/dome-machine.png";

  const SkinAnalizer = (props) => {
    return (
      <div className="full-wh">
        <div className="background-section no-opacity" />
        <div
          className="tech-padding-top-mobile"
          style={{
            position: "relative",
            zIndex: "4",
            paddingTop: "4em",
          }}
        >
          <Row>
            <Col xs={12} className="logo-container-tech">
              <img src={logo} alt="Logo para AdipoShape" />
            </Col>
            <Row className="inner-tech-text">
              <Col className="inner-tech-col" xs={{ order: 2, size: 12 }} md={{ order: 1, size: 4 }}>
                <p>
                  El analizador de piel facial DOME mide clínicamente la superficie de la piel mediante imágenes digitales de
                  última generación, con luz visible RGB, luz polarizada PL y tecnología de imágenes de espectro UV,
                  combinadas con inteligencia artificial y análisis de imágenes.
                </p>
                <p>
                  Es el equipo de detección más avanzado del mundo que captura fotos multiespectrales del rostro, examinando:
                </p>
                <h5>
                <b className="check-left">Poros RGB</b>
                </h5>
                <p>
                  Representa los grandes poros actuales en la superficie de la piel.
                </p>
                <h5>
                  <b className="check-left">Manchas RGB</b>
                </h5>
                <p>
                  Manchas actuales cuyo color es más oscuro que el color normal de la piel y de forma
                  redonda o irregular.
                </p>
                <h5>
                  <b className="check-left">Arrugas RGB</b>
                </h5>
                <p>
                  Estado actual de las arrugas y el área que no es lisa ni plana.
                </p>
                <h5>
                  <b className="check-left">Textura PL</b>
                </h5>
                <p>
                  Es la tendencia actual del desarrollo de la textura de la piel, nos marca la piel seca y
                  congestionada. Predice la aparición futura de arrugas.
                </p>
                <h5>
                  <b className="check-left">Porfirina UV</b>
                </h5>
                <p>
                  Secreción actual de grasa y la distribución de puntos negros actuales en la capa de la dermis.
                </p>
              </Col>
              <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }} style={{paddingTop: "4em"}}>
                <img
                  src={maquina}
                  style={{maxWidth: "400px"}}
                  className="maquina-tech"
                  alt="Maquina AdipoShape"
                />
                <div className="linear-background-white" />
              </Col>
              <Col className="inner-tech-col" xs={{ order: 3, size: 12 }} md={{ order: 3, size: 4 }}>
                
                <h5>
                  <b className="check-left">Pigmentación UV</b>
                </h5>
                <p>
                  Pigmentación actual de la dermis, predice la aparición de futuras manchas.
                </p>
                <h5>
                  <b className="check-left">Humedad UV</b>
                </h5>
                <p>
                  Representa el estado de hidratación de la piel del rostro.
                </p>
                <h5>
                  <b className="check-left">Áreas sensibles</b>
                </h5>
                <p>
                  Representa el estado de sensibilidad de la piel que es fácil que se produzca una alergia
                  al cambiar o usar productos cosméticos con exceso de metales pesados.
                </p>
                <h5>
                  <b className="check-left">Áreas marrones</b>
                </h5>
                <p>
                  Condición del metabolismo de la piel.
                </p>
                <h5>
                  <b className="check-left">Daño UV</b>
                </h5>
                <p>
                  Manchas profundas en la piel causadas por la exposición al sol a largo plazo.
                </p>
                <h5>
                  <b className="check-left">Envejecimiento de la piel</b>
                </h5>
                <p>
                  Representa la predicción del estado de la piel en los próximos 3-5 años si no se
                  realiza algun tratamiento de cuidado de la piel.
                </p>
                <h5>
                  <b className="check-left">Embellecimiento</b>
                </h5>
                <p>
                  Representa la predicción del mejoramiento de la piel al realizar tratamientos profesionales
                  para los problemas actuales de la piel del rostro.
                </p>
              </Col>
            </Row>
          </Row>
        </div>
      </div>
    );
  };

  export default SkinAnalizer;
