import React, { useState, useEffect } from "react";

import { useLocation, Link } from "react-router-dom";

const TechAccordion = (props) => {
  const location = useLocation();
  const [selectedMachine, setMachine] = useState("");

  const machines = [
    {
      title: "LASER CRYSTAL 3D",
      route: "lasercrystal"
    },
    {
      title: "SIRIO",
      route: "sirio"
    },
    {
      title: "Venus Legacy",
      route: "venuslegacy"
    },
    {
      title: "Venus Versa",
      route: "venusversa"
    },
    {
      title: "Hydrafacial",
      route: "hydrafacial"
    },
    {
      title: "OPATRA DOME SKIN ANALIZER",
      route: "skinanalizer"
    },
    {
      title: "Mesoject Gun",
      route: "mesojectgun"
    },
    {
      title: "AdipoShape",
      route: "adiposhape"
    },
    {
      title: "NovaPress Smart",
      route: "novapresssmart"
    },
    {
      title: "Carboxiterapia",
      route: "carboxiterapia"
    },
    {
      title: "ULTRASONIDO",
      route: "uld"
    }
  ];

  useEffect(() => {
    let key = location.hash.replace("#", "");

    if(key === ""){
      key = machines[0].route;
    }

    const machine = machines.find((m) => m.route === key);

    if(machine)
      setMachine(machine.title)
    else{
      setMachine(machines[0].title)
    }
  // eslint-disable-next-line
  }, [location]);


  return (
    <>
      
      <div
        className="animate-opacity tech-menu"
        style={{
          opacity: "1"
        }}
      >
        {machines.map(machine => (
          <Link to={`technology#${machine.route}`}>
          <button
            className="menu-button-mobile"
            style={{
              width: "100%",
              fontWeight: "500",
              letterSpacing: "2px",
              color: "#5F6370",
              border: "none",
              lineHeight: "30px",
              backgroundColor: "white",
              marginLeft: "5px",
              textTransform: "uppercase"
            }}
          >
            {selectedMachine === machine.title && (
              <span
                className="menu-button-mobile"
                style={{
                  fontSize: "20px",
                  color: "#40AFC2",
                }}
              >
                &bull;
              </span>
            )}
            {machine.title}
          </button>
        </Link>
        ))}
        
      </div>
    </>
  );
};

export default TechAccordion;