import React, { useState, useEffect } from "react";

import { Container, Row, Col } from "reactstrap";
import logo from "Landing/Images/lavie-bco.png";

import { useLocation, Link } from "react-router-dom";

import contacto from "assets/agendacita.svg";
import background from "Landing/Images/background-negro.jpg";
import whatsapp from "Landing/Images/whatsapp.png";
import facebook from "Landing/Images/facebook.png";
import instagram from "Landing/Images/instagram.png";
import tiktok from "Landing/Images/tiktok.png";
import youtube from "Landing/Images/youtube.png";
import pinterest from "Landing/Images/pinterest.png";
import botonayuda from "Landing/Images/botonayuda.png";
import TechAccordion from "./technologyAccordion";

var ReactGA = require("react-ga");

const MyNavBar = (props) => {
  const location = useLocation();

  const [openMenu, setMenu] = useState(false);

  const Menus = {
    "medicina-estetica": [
      ["Medicina Estética", "/medicina-estetica"],
      ["Medicina Estética", "/medicina-estetica"],
      ["Therapy", "/therapy"],
      ["Tecnología", "/technology"],
    ],
    "medicina-estetica-facial": [
      ["Medicina Estética", "/medicina-estetica"],
      ["Medicina Estética", "/medicina-estetica"],
      ["Therapy", "/therapy"],
      ["Tecnología", "/technology"],
    ],
    "medicina-estetica-corporal": [
      ["Medicina Estética", "/medicina-estetica"],
      ["Medicina Estética", "/medicina-estetica"],
      ["Therapy", "/therapy"],
      ["Tecnología", "/technology"],
    ],
    "beauty-lounge": [
      ["Medicina Estética", "/medicina-estetica"],
      ["Medicina Estética", "/medicina-estetica"],
      ["Therapy", "/therapy"],
      ["Tecnología", "/technology"],
    ],
    therapy: [
      ["Therapy", "/therapy"],
      ["Medicina Estética", "/medicina-estetica"],
      ["Therapy", "/therapy"],
      ["Tecnología", "/technology"],
    ],
    technology: [
      ["Tecnología", "/technology"],
      ["Medicina Estética", "/medicina-estetica"],
      ["Therapy", "/therapy"],
      ["Tecnología", "/technology"],
    ],
    contact: [
      ["Contacto", "/contact"],
      ["Medicina Estética", "/medicina-estetica"],
      ["Therapy", "/therapy"],
      ["Tecnología", "/technology"],
    ],
    "thank-you": [
      ["¡Muchas Gracias!", "/contact"],
      ["Medicina Estética", "/medicina-estetica"],
      ["Therapy", "/therapy"],
      ["Tecnología", "/technology"],
    ],
  };

  const [menuState, setMenuState] = useState([]);
  const [keyLocation, setKey] = useState("");
  useEffect(() => {
    let key = location.pathname.substring(1);
    if (Menus.hasOwnProperty(key)) {
      setKey(key);
      setMenuState(Menus[key]);

      if (
        key === "medicina-estetica-facial" ||
        key === "medicina-estetica-corporal"
      ) {
        setMenu(true);
      } else {
        setMenu(false);
      }
    }

    const element = document.getElementById("section-md");

    window.scrollTo({
      behavior: element ? "smooth" : "auto",
      top: element ? element.offsetTop : 0,
    });
    // eslint-disable-next-line
  }, [location]);

  return (
    <div
      className="myNavBar"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        backgroundColor: "#3d4254",
        backgroundImage: `url(${background})`,
        textAlign: "left",
      }}
    >
      <Container className="mobile-navbar">
        <Row>
          <Col
            className="center-mobile"
            md={4}
            style={{
              padding: "2em",
            }}
          >
            <Link to="/">
              <img
                src={logo}
                style={{
                  maxWidth: "110px",
                  height: "auto",
                }}
                alt="Logotipo"
              />
            </Link>
          </Col>
        </Row>
        <Row
          className="padding-left-1-mobile"
          style={{
            marginTop: "2em",
            paddingLeft: "2em",
            textAlign: "left",
          }}
        >
          <Col xs={12}>
            {menuState[0] && (
              <h1
                style={{
                  textAlign: "left",
                  fontWeight: "300",
                  fontSize: "38px",
                  letterSpacing: "8px",
                  textTransform: "uppercase",
                  color: "white",
                }}
              >
                {menuState[0][0]}
              </h1>
            )}
          </Col>
          {menuState[0] && menuState[0][1] === "/medicina-estetica" && (
            <Row
              style={{
                fontSize: "15px",
              }}
            >
              <Col xs={6} className="no-pd">
                <Link to="/medicina-estetica">
                  <button
                    className="menu-button-mobile"
                    style={{
                      width: "100%",
                      fontWeight: "500",
                      letterSpacing: "5px",
                      color: "#5F6370",
                      border: "none",
                      lineHeight: "17px",
                      backgroundColor: "white",
                      marginRight: "5px",
                    }}
                  >
                    {keyLocation === "medicina-estetica" && (
                      <span
                        className="menu-button-mobile"
                        style={{
                          fontSize: "20px",
                          color: "#40AFC2",
                        }}
                      >
                        &bull;
                      </span>
                    )}
                    CLÍNICO
                  </button>
                </Link>
              </Col>
              <Col xs={6} className="no-pd">
                <button
                  className="menu-button-mobile"
                  style={{
                    width: "100%",
                    fontWeight: "500",
                    letterSpacing: "2px",
                    color: "#5F6370",
                    border: "none",
                    lineHeight: "17px",
                    backgroundColor: "white",
                    marginLeft: "5px",
                  }}
                  onClick={() => setMenu(!openMenu)}
                >
                  {(keyLocation === "medicina-estetica-facial" ||
                    keyLocation === "medicina-estetica-corporal") && (
                      <span
                        className="menu-button-mobile"
                        style={{
                          fontSize: "20px",
                          color: "#40AFC2",
                        }}
                      >
                        &bull;
                      </span>
                    )}
                  APARATOLOGÍA
                </button>
                <div
                  className="animate-opacity"
                  style={{
                    opacity: `${openMenu ? "1" : "0"}`,
                  }}
                >
                  <Link to="/medicina-estetica-facial">
                    <button
                      className="menu-button-mobile"
                      style={{
                        width: "100%",
                        fontWeight: "500",
                        letterSpacing: "2px",
                        color: "#5F6370",
                        border: "none",
                        lineHeight: "30px",
                        backgroundColor: "white",
                        marginLeft: "5px",
                      }}
                    >
                      {keyLocation === "medicina-estetica-facial" && (
                        <span
                          className="menu-button-mobile"
                          style={{
                            fontSize: "20px",
                            color: "#40AFC2",
                          }}
                        >
                          &bull;
                        </span>
                      )}
                      FACIAL
                    </button>
                  </Link>
                  <Link to="/medicina-estetica-corporal">
                    <button
                      className="menu-button-mobile"
                      style={{
                        width: "100%",
                        fontWeight: "500",
                        letterSpacing: "2px",
                        color: "#5F6370",
                        border: "none",
                        lineHeight: "30px",
                        backgroundColor: "white",
                        marginLeft: "5px",
                      }}
                    >
                      {keyLocation === "medicina-estetica-corporal" && (
                        <span
                          className="menu-button-mobile"
                          style={{
                            fontSize: "20px",
                            color: "#40AFC2",
                          }}
                        >
                          &bull;
                        </span>
                      )}
                      CORPORAL
                    </button>
                  </Link>
                </div>
              </Col>
            </Row>
          )}

          {menuState[0] && menuState[0][1] === "/technology" && (
            <Row
              style={{
                fontSize: "15px",
              }}
            >
              <Col xs={12} className="no-pd">
                <TechAccordion/>
              </Col>
            </Row>
          )}
        </Row>
        <Row
          className="padding-left-1-mobile"
          style={{
            marginTop: "2em",
            paddingLeft: "2em",
            textTransform: "uppercase",
          }}
        >
          {menuState.map((value, index) => {
            if (index === 0) return null;

            return (
              <Col xs={12} key={index}>
                <Link to={value[1]}>
                  <h3
                    style={{
                      fontWeight: "500",
                      letterSpacing: "2px",
                      color: "white",
                      textAlign: "left",
                      fontSize: "18px",
                      textDecoration: "none",
                      marginBottom: "1em",
                    }}
                    className="break-lg"
                  >
                    {value[0]}
                  </h3>
                </Link>
              </Col>
            );
          })}
          <Col xs={6}>
            <Link to="/">
              <h3
                style={{
                  fontWeight: "500",
                  letterSpacing: "2px",
                  color: "white",
                  textAlign: "left",
                  fontSize: "18px",
                  textDecoration: "none",
                  marginBottom: "1em",
                }}
                className="break-lg"
              >
                Home
              </h3>
            </Link>
          </Col>
        </Row>
        <Row
          className="button-social-whatsapp-mobile"
          style={{
            marginTop: "2em",
            paddingLeft: "2em",
          }}
        >
          <Col xs={12}>
            <div className="buttons-social-nav">
              <Row>
                <Col xs={12}>
                  <Link to="/contact">
                    <img
                      src={contacto}
                      style={{
                        maxWidth: "90%",
                        width: "200px",
                        marginBottom: "2em",
                      }}
                      alt="Logo Contacto"
                    />
                  </Link>
                </Col>
              </Row>
            </div>
          </Col>
          <Col xs={12}>
            <div className="buttons-social-nav">
              <Row>
                <Col md={6} xs={12}>
                  <ReactGA.OutboundLink
                    eventLabel="Whatsapp"
                    to="https://api.whatsapp.com/send?phone=5215629993831&text=%C2%A1Hola!%20%C2%A1Me%20interesa%20saber%20acerca%20de%20La%20Vie%20Clinic!"
                    target="_blank"
                    style={{
                      paddingLeft: "1em",
                    }}
                    trackerNames={["Links"]}
                  >
                    <img
                      src={whatsapp}
                      alt="Logo Whatsapp"
                      style={{
                        maxWidth: "70px",
                        cursor: "pointer",
                      }}
                    />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Whatsapp"
                    to="https://api.whatsapp.com/send?phone=5215629993831&text=%C2%A1Hola!%20%C2%A1Me%20interesa%20saber%20acerca%20de%20La%20Vie%20Clinic!"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img
                      src={botonayuda}
                      alt="Boton ayuda"
                      style={{
                        paddingTop: "30px",
                      }}
                      className="boton-ayuda"
                    />
                  </ReactGA.OutboundLink>
                </Col>
              </Row>
              <Row
                className="button-social-mobile"
                style={{
                  marginTop: "2em",
                }}
              >
                <Col xs={12} className="mini-logos">
                  <ReactGA.OutboundLink
                    eventLabel="Instagram"
                    to="https://www.instagram.com/lavieclinicmx/"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={instagram} alt="Logo Instagram" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Facebook"
                    to="https://www.facebook.com/LaVieClinicMx"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={facebook} alt="Logo Facebook" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="TikTok"
                    to="https://www.tiktok.com/@lavieclinicmx"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={tiktok} alt="Logo TikTok" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Youtube"
                    to="https://www.youtube.com/@lavieclinicsatelite"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={youtube} alt="Logo Youtube" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Pinterest"
                    to="https://www.pinterest.com/LaVieClinicMX/"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={pinterest} alt="Logo Pinterest" />
                  </ReactGA.OutboundLink>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default MyNavBar;
