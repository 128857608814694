import { BrowserRouter, Route } from "react-router-dom";
import { spring, AnimatedSwitch } from "react-router-transition";

import AOS from "aos";
import "aos/dist/aos.css";
import "./App.css";

import Landing from "Landing/Landing";
import MainLayout from "Layouts/mainLayout";

import ScrollButton from "Pages/scrollButton";
import MedicinaEstetica from "Pages/medicinaEstetica";
import MedicinaEsteticaFacial from "Pages/medicinaEsteticaFacial";
import MedicinaEsteticaCorporal from "Pages/medicinaEsteticaCorporal";
import Therapy from "Pages/Therapy";
import BeautyLounge from "Pages/beautyLounge";
import Technology from "Pages/technology";
import Contact from "Pages/contact";
import MobileNavBar from "Landing/Partials/MobileNavBar";
import ThankYou from "Pages/ThankYou";

import useGA from "./useGA";

import ReactPixel from 'react-facebook-pixel';
import Bannercastroso from "Landing/Partials/BannerCastroso";

function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 200,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

function Routes() {

  useGA();

  return (
    <>
      <ScrollButton/>
      <MobileNavBar />
      <Route exact path="/">
        <Bannercastroso/>
      </Route>
      <div className="App">
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
          className="route-wrapper"
        >
          <Route exact path="/">
            <Landing />
          </Route>
          <Route path="/contact" component={Contact} />
          <Route path="/thank-you" component={ThankYou} />
          <Route
            path={[
              "/medicina-estetica",
              "/medicina-estetica-facial",
              "/medicina-estetica-corporal",
              "/therapy",
              "/beauty-lounge",
              "/technology",
            ]}
          >
            <MainLayout>
              <Route path="/medicina-estetica" component={MedicinaEstetica} />
              <Route
                path="/medicina-estetica-facial"
                component={MedicinaEsteticaFacial}
              />
              <Route
                path="/medicina-estetica-corporal"
                component={MedicinaEsteticaCorporal}
              />
              <Route path="/therapy" component={Therapy} />
              <Route path="/beauty-lounge" component={BeautyLounge} />
              <Route path="/technology" component={Technology} />
            </MainLayout>
          </Route>
        </AnimatedSwitch>
      </div>
    </>
  );
}

function App() {
  AOS.init();

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: true, // enable logs
  };

  ReactPixel.init('167555512106513', null, options);
  ReactPixel.pageView(); // For tracking page view
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}

export default App;
