import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";

import "Pages/pages.css";
import { Row, Container, Col } from "reactstrap";

import { data } from "data/beautyData";

import ReactSlidy from "react-slidy";
import "react-slidy/lib/styles.css";

import { Accordion } from "Pages/Partials/Accordion";

import tiempo from "Landing/Images/tiempo2.png";

import slide1 from "Pages/Images-Beauty/slide1.jpg";
import slide2 from "Pages/Images-Beauty/slide2.jpg";
import slide3 from "Pages/Images-Beauty/slide3.jpg";

const conditionalClass = (isActive, index) => (isActive ? `active-header` : ``);

const slides_bg = [slide1, slide2, slide3];

const BeautyLounge = (props) => {
  let myRef = useRef();
  const executeScroll = () => {};

  const [actualSlide, setActualSlide] = useState(0);

  const updateSlide = ({ currentSlide }) => {
    executeScroll();
    setActualSlide(currentSlide);
  };

  const initalState = 0;
  const [count, setCount] = useState(initalState);

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 15000);
    return () => clearInterval(interval);
  }, [count]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>La Vie Clinic Beauty Lounge</title>
        <meta
          name="description"
          content="Cuida tu cabello con manos expertas, dale un estilo único a
          tus uñas, ilumina tu mirada y siente la magia de un maquillaje
          profesional."
        />
      </Helmet>
      <Container
        id="section-md"
        className="Section-MD"
        style={{ minHeight: "100vh" }}
      >
        <div
          className="carrousel-background-container"
          style={{
            backgroundImage: `url(${slides_bg[count % 3]})`,
          }}
        >
          <div className="full-wh-i" />
        </div>
        <div className="tiempo-container-2">
          <img src={tiempo} alt="Bottom banner" />
        </div>
        <Row
          style={{
            minHeight: "100vh",
            paddingTop: "10vh",
            textAlign: "left",
            color: "white",
            position: "relative",
            zIndex: "2",
          }}
        >
          <Col
            xs={12}
            style={{
              paddingRight: "10vw",
              paddingLeft: "5vw",
              marginBottom: "1em",
            }}
          >
            <Row>
              <Col xs={12}>
                <h5
                  style={{
                    fontSize: "23px",
                    marginBottom: "2em",
                  }}
                >
                  ¡Reinvéntate con el poder de un cambio de imagen!
                </h5>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <h5
                  style={{
                    fontSize: "23px",
                    marginBottom: "2em",
                  }}
                >
                  Cuida tu cabello con manos expertas, dale un estilo único a
                  tus uñas, ilumina tu mirada y siente la magia de un maquillaje
                  profesional.
                </h5>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            className="hide-mobile"
            style={{ paddingRight: "5vw", paddingLeft: "5vw" }}
          >
            <Row>
              <Col md={4}>
                <ul className="selector-beauty">
                  {data.map((item, index) => {
                    return (
                      <li
                        key={index}
                        className={conditionalClass(
                          index === actualSlide,
                          index
                        )}
                        onClick={() => updateSlide({ currentSlide: index })}
                      >
                        {item.title}
                      </li>
                    );
                  })}
                </ul>
              </Col>
              <Col md={8}>
                <div ref={myRef} className="inner-beauty">
                  <ReactSlidy
                    doAfterSlide={updateSlide}
                    slide={actualSlide}
                    showArrows={false}
                    lazyLoadSlider={false}
                    itemsToPreload={9}
                  >
                    {data.map((aItem, index) => (
                      <Row key={index}>
                        <Col xs={12}>
                          <ul className="list-beauty">
                            {aItem.data.map((item, index_n) => {
                              return <li>{item}</li>;
                            })}
                          </ul>
                        </Col>
                      </Row>
                    ))}
                  </ReactSlidy>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            xs={12}
            className="show-mobile custom-accordion"
            style={{ paddingRight: "10vw", paddingLeft: "10vw" }}
          >
            <Row>
              <Accordion open={0}>
                {data.map((item, index_n) => {
                  return (
                    <Accordion.Item key={index_n}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body>
                        <Row
                          style={{
                            whiteSpace: "normal",
                          }}
                        >
                          <Col md={12}>
                            <Row>
                              {item.data.map((item_b, index) => {
                                return (
                                  <Col xs={12}>
                                    <div>
                                      <p>{item_b}</p>
                                    </div>
                                  </Col>
                                );
                              })}
                            </Row>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default BeautyLounge;
