import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/VENUSLEGAVY.png";
import imgp from "Pages/Images-Tecnology/vlegacy-pic.png";
import maquina from "Pages/Images-Tecnology/vlegacy-machine.png";

const Venus = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" />
          </Col>
          <Col
            xs={12}
            style={{
              marginTop: "1em",
            }}
          >
            <div
              style={{
                width: "60%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="inline-list"
            >
              <h5>
                EL DISPOSITIVO MÁS AVANZADO QUE EFICAZMENTE RESUELVE LOS
                PROBLEMAS DE ESTÉTICA FACIAL Y CORPORAL SIN CIRUGÍA
              </h5>
              <ul>
                <li>REMODELACIÓN CORPORAL</li>
                <li>
                  REDUCCIÓN DE CELULITIS <br />
                </li>
                <li>TENSADO DE LA PIEL</li>
                <li>REDUCCIÓN DE ARRUGAS</li>
              </ul>
            </div>
          </Col>
          <Row className="inner-tech-text">
            <Col className="inner-tech-col" xs={{ order: 2, size: 12 }} md={{order: 1, size: 4}}>
              <h5 style={{ marginBottom: "1em" }}>
                <b>Beneficios</b>
              </h5>
              <ul style={{ marginBottom: "2em" }}>
                <li>Los tratamientos son cómodos</li>
                <li>De 6 a 8 sesiones por área</li>
                <li>
                  Tecnología de Radiofrecuencia segura y eficaz para todos los
                  tonos de piel
                </li>
                <li>Agradable y relajante tratamiento sin incapacidad</li>
              </ul>
              <img src={imgp} alt="Fotografia de caso" />
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{order: 2, size: 4}}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col className="inner-tech-col" xs={{ order: 3, size: 12 }} md={{order: 3, size: 4}}>
              <h5
                style={{
                  marginBottom: "1em",
                }}
              >
                Combina Radiofrecuencia Multipolar y Campos Electromagnéticos
                Pulsados
              </h5>
              <p className="check-left">
                <b>La Radiofrecuencia Multipolar</b> se conduce a través de la
                piel, creando calor y desencadenando una respuesta de curación
                de heridas, lo que hace que el colágeno se contraiga.
              </p>
              <p className="check-left">
                <b>Campos Electromagnéticos Pulsados</b> con tecnología
                VariPulse que emite impulsos de succión ajustable que facilita
                una lipolisis efectiva. Ayuda a que la piel sane, creando nuevo
                colágeno y elastina, haciendo que la piel luzca más suave y
                juvenil.
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default Venus;
