import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/multi-logo.png";
import maquina from "Pages/Images-Tecnology/carboxiterapia-machine.png";

const Carboxiterapia = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" style={{}} />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" className="small-img" style={{ width: "4px!important" }} />
          </Col>
          {/* <Col
            xs={12}
            style={{
              marginTop: "3em",
            }}
          >
            <div
              style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <h1>CARBOXITERAPIA</h1>
            </div>
          </Col> */}
          <Row className="inner-tech-text">
            <Col
              className="inner-tech-col"
              xs={{ order: 2, size: 12 }}
              md={{ order: 1, size: 4 }}
            >
              <h5>
                <b>CARBOXITERAPIA</b>
              </h5>
              <p>
                Consiste en la aplicación de inyecciones de gas carbónico por
                debajo de la piel, permitiendo estimular la circulación celular,
                la oxigenación de los tejidos y la formación de nuevas fibras de
                colágeno. Efectiva para:
              </p>
              <ul>
                <li>
                  <b>Celulitis y grasa localizada:</b> Elimina la grasa a través
                  de la rotura de los adipocitos, aumenta la circulación
                  sanguínea y el drenaje linfático.
                </li>
                <li>
                  <b>Estrías:</b> Estira los tejidos y los rellena con gas,
                  estimulando la producción de colágeno; las cremas aplicadas se
                  absorben mejor en la piel.
                </li>
                <li>
                  <b> Flacidez:</b> Favorece la producción de fibras de colágeno
                  y elastina.
                </li>
                <li>
                  <b> Ojeras: </b> Reduce la inflamación, mejora la circulación
                  y aclara la piel de la zona.{" "}
                </li>
                <li>
                  <b>Heridas crónicas: </b> Mejora la cicatrización de la piel y
                  la formación de nuevas fibras de colágeno.{" "}
                </li>
                <li>
                  <b>Pérdida de cabello:</b> Favorece su crecimiento y el
                  aumento de flujo sanguíneo en el cuero cabelludo.
                </li>
              </ul>
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col
              className="inner-tech-col"
              xs={{ order: 3, size: 12 }}
              md={{ order: 3, size: 4 }}
            >
              <p>
                El equipo de Carboxiterapia permite una administración estéril,
                con control de velocidad, tiempo y monitoreo del porcentaje de
                dosis administrada.
              </p>
              <h5>
                <b>MICROINYECCIONES DE DIÓXIDO DE CARBONO </b>
              </h5>

              <p>
                Al aplicar dióxido de carbono (CO2) se obtiene como resultado la
                oxigenación de las células corporales y un aumento de colágeno
                en la piel. Con esta terapia los tejidos son más firmes, la piel
                se vuelve más tersa y se obtiene un efecto de rejuvenecimiento,
                aliviando síntomas de celulitis como la pesadez y el volumen
                excesivo de la zona. Este tratamiento puede ser complementado
                con masaje subdérmico micro alveolar o presoterapia; cualquiera
                de ellos colabora al aumentar la difusión de gas a tejidos
                adyacentes.
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default Carboxiterapia;
