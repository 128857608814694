import { Col, Row } from "reactstrap";
import MyNavBar from "Pages/Partials/myNavBar";
import { useEffect } from "react";

const MainLayout = (props) => {
  useEffect(() => {
    const element = document.getElementById("section-md");

    window.scrollTo({
      behavior: element ? "smooth" : "auto",
      top: element ? element.offsetTop : 0,
    });
  }, []);

  return (
    <div
      className="main"
      style={{
        minHeight: "100vh",
        width: "100vw",
        maxWidth: "100%",
        overflowX: "hidden"
      }}
    >
      <Row
        style={{
          minHeight: "100vh",
          overflowX: "hidden"
        }}
      >
        <Col
          xs={12}
          lg={4}
          style={{
            minHeight: "100%",
            paddingRight: "0",
          }}
        >
          <MyNavBar />
        </Col>
        <Col
          xs={12}
          lg={8}
          style={{
            padding: "0",
          }}
        >
          {props.children}
        </Col>
      </Row>
    </div>
  );
};

export default MainLayout;
