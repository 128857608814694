import React from "react";
import { Helmet } from "react-helmet";

import { Row, Col, Container } from "reactstrap";
import { Link } from "react-router-dom";
import "Pages/pages.css";

import contacto from "assets/agendar_gris.svg";
import gracias from "assets/gracias.svg";
import background from "Landing/Images/background_landing.jpg";
import logo from "Landing/Images/Logo.png";
import tiempo from "Landing/Images/tiempor.png";
import MyNavBar from "./Partials/myNavBar";

//import { sendEvent } from "analytics";

const paths = [
  ["Home", "/"],
  ["Medicina Estética", "/medicina-estetica"],
  ["Beauty Lounge", "/beauty-lounge"],
  ["Therapy", "/therapy"],
  ["Tecnología", "/technology"],
];
const ThankYou = (props) => {
  return (
    <div
      id="section-md"
      className="main"
      style={{
        backgroundImage: `url(${background})`,
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>La Vie Clinic Contact</title>
        <meta
          name="description"
          content="En La Vie queremos escuchar tu opinión."
        />
      </Helmet>
      <div className="show-mobile">
        <MyNavBar />
      </div>

      <div className="contact-container">
        <Row
          style={{
            position: "relative",
            zIndex: "5",
          }}
        >
          <Col md={4} className="contact-navbar hide-mobile">
            <ul className="contact-list">
              {paths.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item[1]}>
                      <h5
                        style={{
                          fontWeight: 500,
                          color: "#5F6370",
                        }}
                      >
                        {item[0]}
                      </h5>
                    </Link>
                  </li>
                );
              })}
              <li className="active-link">
                <Link to={"/contact"}>
                  <img
                    src={contacto}
                    style={{
                      maxWidth: "90%",
                      width: "200px",
                    }}
                    alt="Logo Contacto"
                  />
                </Link>
              </li>
            </ul>
          </Col>
          <Col
            md={4}
            style={{
              minHeight: "100vh",
              maxHeight: "100vh",
              overflow: "hidden",
            }}
          >
            <Row>
              <Col>
                <div className="center-banner">
                  <Link to="/">
                    <img src={logo} alt="Logo" />
                  </Link>

                  <Container>
                    <Row>
                      <Col
                        xs={12}
                        style={{
                          marginBottom: "2em",
                        }}
                      >
                        <h5
                          style={{
                            fontWeight: "400",
                            color: "#5F6370",
                          }}
                        >
                          TU MENSAJE HA SIDO ENVIADO
                        </h5>
                        <img
                          src={gracias}
                          style={{
                            paddingTop: "1em",
                            marginBottom: "1em",
                            width: "100%",
                          }}
                          alt="Gracias"
                        />
                        <h5
                          style={{
                            fontWeight: "400",
                            color: "#5F6370",
                          }}
                        >
                          EN BREVE NOS COMUNICAREMOS CONTIGO
                        </h5>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
            <Row style={{ height: "100%", padding: "5em" }}>
              <Col>
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    backgroundColor: "#5F6370",
                  }}
                ></div>
              </Col>
            </Row>
          </Col>

          <Col xs={4} className="hide-mobile">
            <div className="image-loguin">
              <img src={tiempo} alt="banner" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ThankYou;
