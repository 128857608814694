export const data = {
  radiofrecuencia: {
    title: "Radiofrecuencia",
    data: [
      {
        title: "Pre-quirúrgico corporal",
        desc: "Este tratamiento es una de las claves del resultado exitoso de una cirugía estética, son esenciales para la preparación de la piel y los tejidos de la zona que será sometida a una cirugía, por ejemplo, lipectomía, liposucción, lipotransferencia, etc; reduciendo el tiempo de recuperación de la cirugía. Es necesario preparar los tejidos con al menos dos o tres meses de anticipación.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 120 min",
      },
      {
        title: "Post-quirúrgico corporal",
        desc: "Nuestro tratamiento comenzará en el momento que el cirujano lo indique. El objetivo será ayudar en el proceso de regeneración de la piel, reactivar el flujo sanguíneo y linfático, aliviando el dolor, disminuyendo los hematomas, incrementando la elasticidad del tejido, evitando la formación de fibrosis y logrando una recuperación más rápida.",
        pack: "10 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 120 min",
      },
      {
        title: "Moldeado y anticelulítico",
        desc: "Nuestro tratamiento con tecnología láser, vacum, radiofrecuencia y sistema CBA patentado combinado con presoterapia logra una sinergia capaz de reducir la molesta celulitis mejorando la circulación, decodificando las piernas y estimulando la reducción de la grasa, dejando unas piernas bellas.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 70 min",
      },
    ],
  },
  presoterapia: {
    title: "Presoterapia",
    data: [
      {
        title: "Drenaje linfático",
        desc: "Tratamiento indicado para lograr un drenaje linfático en personas que presentan alteraciones en el sistema circulatorio, por ejemplo, piernas cansadas y arañitas vasculares.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Drenaje venoso",
        desc: "Nuestro tratamiento innovador esta indicado para estimular el sistema circulatorio, contribuyendo así con la eliminación de líquidos, grasas y toxinas que generan la celulitis y trastornos venosos como las várices.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Descontracturante",
        desc: "Tratamiento que ayuda a eliminar todas las sustancias que quedan en tus vasos sanguíneos y músculos después de mucho entrenamiento seguido. Además, consigue bombear una gran cantidad de sangre hacia las piernas. Este aumento de la vascularización es clave para oxigenar y ayudar en la recuperación de los músculos.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Masaje pre-parto para embarazada",
        desc: "La presoterapia pre-parto es uno de los tratamientos que por su efectividad, ayuda a mejorar la circulación sanguínea, ejerciendo una presión controlada en la zona a tratar. Es un método que ayudan a disminuir la retención de líquidos y prevenir los posibles riesgos.",
        notes:
          "*Se recomienda después de la semana 12 de gestación previa autorización del ginecólogo.",
        pack: "Mínimo 5 sesiones",
        session: "1 sesión por semana",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Masaje post-parto",
        desc: "La presoterapia post-parto es uno de los tratamientos más utilizados por su efectividad, ayuda a mejorar la circulación sanguínea, ejerciendo una presión controlada en la zona a tratar. Es uno de los métodos de recuperación después del parto que puede ayudarnos con nuestro sistema circulatorio.",
        pack: "10 sesiones",
        session: "1 sesión por semana",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "Pre-quirúrgico corporal",
        desc: "Este tratamiento es una de las claves del resultado exitoso de una cirugía estética, son esenciales para la preparación de la piel y los tejidos de la zona que será sometida a una cirugía, por ejemplo, lipectomía, liposucción, lipotransferencia, etc; reduciendo el tiempo de recuperación de la cirugía. Es necesario preparar los tejidos con al menos dos o tres meses de anticipación.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 120 min",
      },
      {
        title: "Post-quirúrgico corporal",
        desc: "Nuestro tratamiento comenzará en el momento que el cirujano lo indique. El objetivo será ayudar en el proceso de regeneración de la piel, reactivar el flujo sanguíneo y linfático, aliviando el dolor, disminuyendo los hematomas, incrementando la elasticidad del tejido, evitando la formación de fibrosis y logrando una recuperación más rápida.",
        pack: "10 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 120 min",
      },
    ],
  },
  laserCBA: {
    title: "LASER CBA",
    data: [
      {
        title: "Reducción de abdomen y contorno de cintura",
        desc: "Tratamiento con tecnología láser, radiofrecuencia, vacum y sistema CBA patentado, logrará que tu abdomen pierda volumen y tu cintura se vea más definida.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Reducción de brazos y espalda",
        desc: "Tratamiento con tecnología láser, vacum y sistema CBA patentado, ayuda a reducir y reafirmar los brazos y espalda, promueve y mejora las funciones celulares de la piel, reduciendo la grasa y reafirmando los músculos.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Reducción de grasa localizada en muslos y piernas",
        desc: "Nuestro tratamiento con tecnología láser, vacum y sistema CBA patentado, ayuda a reducir la molesta grasa en piernas, mejorando la calidad de la piel.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Moldeado y anticelulítico",
        desc: "Nuestro tratamiento con tecnología láser, vacum, radiofrecuencia y sistema CBA patentado combinado con presoterapia logra una sinergia capaz de reducir la molesta celulitis mejorando la circulación, decodificando las piernas y estimulando la reducción de la grasa, dejando unas piernas bellas.",
        pack: "8 a 15 sesiones (Dependiendo valoración del médico)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 70 min",
      },
    ],
  },
  luzpulsada: {
    title: "Luz pulsada",
    data: [
      {
        title: "Eliminación de telangiectasias en piernas",
        desc: "¿Qué son las telangiectasias? Son venas de calibre muy fino (1 mm o menos) y de color rojo intenso que pueden aparecer en las piernas, cara, nariz y mejillas. Nuestra tecnología de luz pulsada te ayudará a disminuir las telangiectasias.",
        pack: "4 a 5 sesiones (Dependiendo valoración del médico)",
        session: "1 cada tercera semana, Mantenimiento 2 a 4 sesiones por año",
        time: "Tiempo estimado del procedimiento 60 min",
      },
    ],
  },
  radiofrecuenciaVaripulse: {
    title: "Radiofrecuencia\ncon Varipulse",
    data: [
      {
        title: "REDUCCIÓN Y TENSIÓN DE ABDOMEN",
        desc: "Nuestro equipo utiliza radiofrecuencia multipolar y campos electromagnéticos pulsados para aplicar calor por debajo de la superficie de la piel. Este proceso ayuda a estimular de forma natural la formación de fibras de colágeno y elastina, logrando un abdomen estilizado y esculpido, con una piel más tensa.",
        pack: "Paquete de 10 sesiones",
        session: "1 sesión por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Reafirmante de brazos y espalda",
        desc: "Nuestro equipo utiliza radiofrecuencia multipolar y campos electromagnéticos pulsados para aplicar calor por debajo de la superficie de la piel. Este proceso ayuda a estimular de forma natural la formación de fibras de colágeno y elastina, dando como resultado brazos y espalda más firmes.",
        pack: "Paquete de 10 sesiones",
        session: "1 sesión por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Reafirmación y lifting de busto",
        desc: "Nuestro tratamiento innovador que utiliza radiofrecuencia multipolar y campos electromagnéticos pulsados para aplicar calor por debajo de la superficie de la piel. Este proceso ayuda a estimular de forma natural la formación de fibras de colágeno y elastina, mejora el aspecto de la piel flácida del busto.",
        pack: "Paquete de 10 sesiones",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Firmeza y tensión de piernas",
        desc: "Nuestro tratamiento con radiofrecuencia multipolar y campos electromagnéticos pulsados, aplica calor por debajo de la superficie de la piel, estimulando la formación de fibras de colágeno y elastina, dando como resultado un aspecto más estilizado y una piel más firme.",
        pack: "Paquete de 10 sesiones",
        session: "1 sesión por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "FIRMEZA Y TENSIÓN DE GLÚTEOS Y CHAPARRERAS",
        desc: "Nuestro tratamiento con radiofrecuencia multipolar y campos electromagnéticos pulsados, aplica calor por debajo de la superficie de la piel, estimulando la formación de fibras de colágeno y elastina, dando como resultado un aspecto más estilizado y una piel más firme.",
        session: "1 sesión por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
    ],
  },
  radiofrecuenciaNano: {
    title: "Radiofrecuencia\nNanofraccionada",
    data: [
      {
        title: "REDUCCIÓN DE ESTRÍAS",
        desc: "Es un tratamiento de Radiofrecuencia nanofraccionada que estimula la actividad de las células que se encargan de producir las fibras de colágeno, mejorando las estrías. Nuestro novedoso equipo ayuda a eliminar estas marcas de la piel que resultan de un excesivo estiramiento por motivos como el embarazo, subidas de peso o crecimiento natural.",
        pack: "Paquete de 3 sesiones",
        session: "1 sesión por mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
    ],
  },
  estimulacion: {
    title: "HIFEM (ESTIMULACIÓN\nMUSCULAR) ",
    data: [
      {
        title: "SIRIO",
        desc: "Tratamiento que promueve la reafirmación de la piel, el fortalecimiento y tonificación de los músculos. Trabaja con contracciones que llegan a través de aplicadores colocados en el área del músculo objetivo.",
        pack: "6 a 10 sesiones (Dependiendo valoración médica)",
        session: "2 o 3 sesiones por semana",
        time: "Tiempo estimado del procedimiento: 30 min por zona",
      },
    ],
  },
  depilacion: {
    title: "DEPILACIÓN\nLÁSER",
    data: [
      {
        title: "DEPILACIÓN CORPORAL DEFINITIVA",
        desc: "Laser Crystal 3D es la tecnología más avanzada, segura y eficaz en depilación láser. La eliminación de vello es realizada por la transmisión de la energía del láser al folículo piloso. Mezcla los beneficios de las 3 longitudes de ondas, logrando resultados antes impensables. Cuenta con CryoCooling, sistema de refrigeración sinérgico que genera un efecto anestésico y protector en la epidermis. Seguro, Eficaz, y Placentero. \n\nLas áreas de aplicación corporal se pueden realizar en brazos, axilas, pecho, abdomen, espalda, bikini, glúteos y piernas.",
        pack: "10 sesiones",
        session: "1 sesión al mes",
        time: "Tiempo estimado del procedimiento: 10-40 min., dependiendo del área a tratar.",
      },
    ],
  },
  ultrasonido: {
    title: "ULTRASONIDO",
    data: [
      {
        title: "ULTRASONIDO POST-QUIRÚRGICO\n(CICATRICES, FIBROSIS, ABDOMINOPLASTIA)",
        desc: "Es recomendado para personas que se sometieron a una cirugía, para prevenir una cicatrización queloide, haciendo una ruptura de fibras excesivas de colágeno, estimulando el flujo sanguíneo y liberando toxinas, desinflamando y disminuyendo el dolor en la zona.",
        pack: "12 a 15 sesiones (Dependiendo valoración médica)",
        session: "1 o 2 sesiones por semana",
        time: "Tiempo estimado del procedimiento: 50 min.",
      },
      {
        title: "CELULITIS EN PIERNAS Y GLÚTEOS",
        desc: "Ayuda a reafirmar la piel, desinflama, estimula la producción de colágeno y mejora la circulación sanguínea. El calor que penetra en las capas más profundas de la piel facilita el metabolismo y reduce los depósitos de grasa y celulitis, estrías y varices grado 1, además mejora el drenaje linfático ayudando a eliminar toxinas.",
        pack: "12 a 15 sesiones (Dependiendo valoración médica)",
        session: "2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 50 - 60 min",
      },
      {
        title: "REDUCTIVO DE ABDOMEN",
        desc: "Ayuda a reafirmar la piel, desinflama, estimula la producción de colágeno y mejora la circulación sanguínea. El calor que penetra en las capas más profundas de la piel facilita el metabolismo y reduce los depósitos de grasa y celulitis, estrías y varices grado 1, además mejora el drenaje linfático ayudando a eliminar toxinas.",
        pack: "12 a 15 sesiones (Dependiendo valoración médica)",
        session: "2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 50 - 60 min",
      },
      {
        title: "REDUCTIVO DE ESPALDA Y BRAZOS",
        desc: "Ayuda a reafirmar la piel, desinflama, estimula la producción de colágeno y mejora la circulación sanguínea. El calor que penetra en las capas más profundas de la piel facilita el metabolismo y reduce los depósitos de grasa y celulitis, estrías y varices grado 1, además mejora el drenaje linfático ayudando a eliminar toxinas.",
        pack: "12 a 15 sesiones (Dependiendo valoración médica)",
        session: "2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 50 - 60 min",
      },
      {
        title: "REDUCTIVO Y DRENAJE LINFÁTICO EN PIERNAS",
        desc: "Ayuda a reafirmar la piel, desinflama, estimula la producción de colágeno y mejora la circulación sanguínea. El calor que penetra en las capas más profundas de la piel facilita el metabolismo y reduce los depósitos de grasa y celulitis, estrías y varices grado 1, además mejora el drenaje linfático ayudando a eliminar toxinas.",
        pack: "12 a 15 sesiones (Dependiendo valoración médica)",
        session: "2 sesiones por semana",
        time: "Tiempo estimado del procedimiento 50 - 60 min",
      },
     
    ],
  },
  carbo: {
    title: "CARBOXITERAPIA CORPORAL",
    data: [
      {
        title: "CARBOXITERAPIA",
        desc: "Tratamiento que favorece la oxigenación de las células corporales mediante la infiltración de CO2 (dióxido de carbono). Este gas, ayuda a mejorar la circulación sanguínea al conseguir que los tejidos subcutáneos se relajen provocando la tensión de la piel y se produzca una dilatación vascular, acelera el metabolismo y crea la reproducción de células de colágeno y elastina.\n\nLa carboxiterapia está indicada para pacientes con: piel de naranja, celulitis, fibrosis, flacidez, grasa localizada, estrías y tensión en la piel. \nLas zonas que pueden tratarse son: abdomen, piernas, brazos, espalda, glúteos o cuerpo completo.",
        session: "1 o 2 sesiones por semana ",
        time: "Tiempo estimado del procedimiento: 20-30 min.",
      },
    ],
  },
  
};
