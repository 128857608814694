import React, { useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet";

import {
  Row,
  Col,
  Container,
  Form,
  FormGroup,
  Button,
  Input,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import "Pages/pages.css";

import contacto from "assets/agendacita.svg";
import telefono from "assets/telefono.svg";
import telefono2 from "assets/telefono2.svg";
import background from "Landing/Images/background-negro.jpg";
import logo from "Landing/Images/lavie-bco.png";
import whatsapp from "Landing/Images/whatsapp.png";
import facebook from "Landing/Images/facebook.png";
import instagram from "Landing/Images/instagram.png";
import tiktok from "Landing/Images/tiktok.png";
import youtube from "Landing/Images/youtube.png";
import pinterest from "Landing/Images/pinterest.png";
import botonayuda from "Landing/Images/botonayuda.png";
import tiempo from "Landing/Images/tiempor.png";
import chevron from "Landing/Images/chevron.png";
import maps from "Landing/Images/maps.png";
import MyNavBar from "./Partials/myNavBar";

var ReactGA = require("react-ga");

const paths = [
  ["Home", "/"],
  ["Medicina Estética", "/medicina-estetica"],
  ["Therapy", "/therapy"],
  ["Tecnología", "/technology"],
];
const Contact = (props) => {
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [rLabel, setRlabel] = useState("");

  const [formBody, setForm] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
    tratamiento: "",
  });

  let { name, email, number, message, tratamiento } = formBody;

  const onChange = (e) =>
    setForm({ ...formBody, [e.target.name]: e.target.value });

  const toggleClass = () => {
    setOpen(!isOpen);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    if (
      name === "" ||
      email === "" ||
      number === "" ||
      message === "" ||
      tratamiento === ""
    ) {
      setRlabel("¡Por favor llene todos los campos!");
    } else {
      //sendEvent
      axios
        .post("https://lavieclinic.com/mail.php", {
          name,
          email,
          number,
          message,
          tratamiento,
        })
        .then((response) => {
          if (response.data === "Success") {
            ReactGA.event({
              category: "Lead",
              action: "Usuario a mandando un correo",
            });
            setForm({
              name: "",
              email: "",
              number: "",
              message: "",
              tratamiento: "",
            });
            history.push("/thank-you");
          }
        })
        .catch((response) => {
          setRlabel("Hubo un error al enviar el mensaje.");
        });
    }
  };

  return (
    <div
      id="section-md"
      className="main"
      style={{
        backgroundImage: `url(${background})`,
        overflowX: "hidden"
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>La Vie Clinic Contact</title>
        <meta
          name="description"
          content="En La Vie queremos escuchar tu opinión."
        />
      </Helmet>
      <div className="show-mobile">
        <MyNavBar />
      </div>

      <div className="contact-container">
        <Row
          style={{
            position: "relative",
            zIndex: "5",
          }}
        >
          <Col md={4} className="contact-navbar hide-mobile">
            <ul className="contact-list">
              {paths.map((item, index) => {
                return (
                  <li key={index}>
                    <Link to={item[1]}>
                      <h5>{item[0]}</h5>
                    </Link>
                  </li>
                );
              })}
              <li className="active-link">
                <Link to={"/contact"}>
                  <img
                    src={contacto}
                    style={{
                      maxWidth: "90%",
                      width: "200px",
                    }}
                    alt="Logo Contacto"
                  />
                </Link>
              </li>
            </ul>
            <h5>
              <ReactGA.OutboundLink
                eventLabel="Link Correo"
                to="mailto:info@lavieclinic.com"
                style={{
                  textTransform: "lowercase",
                  fontSize: "23px",
                }}
                trackerNames={["Links"]}
              >
                info@lavieclinic.com
              </ReactGA.OutboundLink>
            </h5>
            <h5>
              <ReactGA.OutboundLink
                eventLabel="Link Telefono"
                to="tel:5592345811"
                style={{
                  textTransform: "lowercase",
                  fontSize: "23px",
                }}
                trackerNames={["Links"]}
              >
                <img
                  src={telefono}
                  style={{
                    maxWidth: "90%",
                    width: "200px",
                  }}
                  alt="Logo telefono"
                />
              </ReactGA.OutboundLink>
            </h5>
            <h5>
              <ReactGA.OutboundLink
                eventLabel="Link Telefono"
                to="tel:5629993831"
                style={{
                  textTransform: "lowercase",
                  fontSize: "23px",
                }}
                trackerNames={["Links"]}
              >
                <img
                  src={telefono2}
                  style={{
                    maxWidth: "90%",
                    width: "200px",
                  }}
                  alt="Logo telefono"
                />
              </ReactGA.OutboundLink>
            </h5>
            <div className="buttons-social">
              <Row>
                <Col md={6}>
                  <ReactGA.OutboundLink
                    eventLabel="Whatsapp"
                    to="https://api.whatsapp.com/send?phone=5215629993831&text=%C2%A1Hola!%20%C2%A1Me%20interesa%20saber%20acerca%20de%20La%20Vie%20Clinic!"
                    target="_blank"
                    style={{
                      paddingLeft: "1em",
                    }}
                    trackerNames={["Links"]}
                  >
                    <img src={whatsapp} alt="Logo Whatsapp" />
                    <div className="divider">
                      <div className="line"></div>
                    </div>
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                      eventLabel="Instagram"
                      to="https://www.instagram.com/lavieclinicmx/"
                      target="_blank"
                      trackerNames={["Links"]}
                    >
                      <img src={instagram} alt="Logo Instagram" />
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                      eventLabel="Facebook"
                      to="https://www.facebook.com/LaVieClinicMx"
                      target="_blank"
                      trackerNames={["Links"]}
                    >
                      <img src={facebook} alt="Logo Facebook" />
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                    eventLabel="TikTok"
                    to="https://www.tiktok.com/@lavieclinicmx"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={tiktok} alt="Logo TikTok" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Youtube"
                    to="https://www.youtube.com/@lavieclinicsatelite"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={youtube} alt="Logo Youtube" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Pinterest"
                    to="https://www.pinterest.com/LaVieClinicMX/"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={pinterest} alt="Logo Pinterest" />
                  </ReactGA.OutboundLink>
                </Col>
              </Row>
              <Row>
                <Col xs={4}>
                  <ReactGA.OutboundLink
                    eventLabel="Whatsapp"
                    to="https://api.whatsapp.com/send?phone=5215629993831&text=%C2%A1Hola!%20%C2%A1Me%20interesa%20saber%20acerca%20de%20La%20Vie%20Clinic!"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img
                      src={botonayuda}
                      alt="Boton ayuda"
                      style={{
                        maxWidth: "100px",
                      }}
                    />
                  </ReactGA.OutboundLink>
                </Col>
              </Row>
            </div>
          </Col>
          <Col md={4}>
            <div className="center-banner">
              <Link to="/">
                <img src={logo} alt="Logo" className="hide-mobile" />
              </Link>

              <Container>
                <Row>
                  <Col
                    xs={12}
                    style={{
                      marginBottom: "2em",
                    }}
                  >
                    <h3
                      style={{
                        fontWeight: "300",
                      }}
                    >
                      CITAS
                    </h3>
                    <h5>
                      Para mayor información y/o solicitar una valoración con
                      nuestros profesionales, contáctanos:
                    </h5>
                  </Col>
                </Row>
                <Form onSubmit={onSubmit}>
                  <Row>
                    <Col xs={12}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          value={name}
                          required={true}
                          onChange={onChange}
                          placeholder="Nombre"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className="no-pr">
                      <FormGroup>
                        <Input
                          type="email"
                          name="email"
                          id="email"
                          value={email}
                          required={true}
                          onChange={onChange}
                          placeholder="E-mail"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={6} className="no-pl">
                      <FormGroup>
                        <Input
                          type="text"
                          name="number"
                          id="number"
                          value={number}
                          required={true}
                          onChange={onChange}
                          placeholder="Móvil"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Input
                          type="text"
                          name="tratamiento"
                          id="tratamiento"
                          value={tratamiento}
                          required={true}
                          onChange={onChange}
                          placeholder="Tratamiento"
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12}>
                      <FormGroup>
                        <Input
                          type="textarea"
                          name="message"
                          id="message"
                          value={message}
                          required={true}
                          onChange={onChange}
                          placeholder="Mensaje"
                          rows="6"
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      xs={12}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <Button>ENVIAR</Button>
                      <h5
                        style={{
                          paddingTop: "1em",
                        }}
                      >
                        {rLabel}
                      </h5>
                    </Col>
                  </Row>
                </Form>
              </Container>
            </div>
          </Col>
          <Col xs={12} className="show-mobile" style={{ padding: "1em" }}>
            <h5>
              <ReactGA.OutboundLink
                eventLabel="Link Correo"
                to="mailto:info@lavieclinic.com"
                style={{
                  textTransform: "lowercase",
                  fontSize: "23px",
                  color: "white"
                }}
                trackerNames={["Links"]}
              >
                info@lavieclinic.com
              </ReactGA.OutboundLink>
            </h5>
            <h5>
              <ReactGA.OutboundLink
                eventLabel="Link Correo"
                to="tel:5592345811"
                style={{
                  textTransform: "lowercase",
                  fontSize: "26px",
                }}
                trackerNames={["Links"]}
              >
                <img
                  src={telefono}
                  style={{
                    maxWidth: "90%",
                    width: "200px",
                  }}
                  alt="Logo telefono"
                />
              </ReactGA.OutboundLink>
            </h5>
            <h5>
              <ReactGA.OutboundLink
                eventLabel="Link Telefono"
                to="tel:5629993831"
                style={{
                  textTransform: "lowercase",
                  fontSize: "23px",
                }}
                trackerNames={["Links"]}
              >
                <img
                  src={telefono2}
                  style={{
                    maxWidth: "90%",
                    width: "200px",
                  }}
                  alt="Logo telefono"
                />
              </ReactGA.OutboundLink>
            </h5>
            <ReactGA.OutboundLink
              eventLabel="Maps"
              to="https://goo.gl/maps/JukmTHDBP3EFpWwA7"
              target="_blank"
              trackerNames={["Links"]}
              style={{
                color: "white",
              }}
            >
              <h5
                style={{
                  paddingTop: "3em",
                  paddingBottom: "3em",
                }}
              >
                Circuito Educadores #22K,Ciudad Satelite 53100 Estado de México,
                México
              </h5>
            </ReactGA.OutboundLink>
          </Col>
          <Col xs={4} className="hide-mobile">
            <div className="image-loguin">
              <img src={tiempo} alt="banner" />
            </div>
            <div className="maps">
              <Row
                style={{
                  cursor: "pointer",
                }}
                onClick={() => toggleClass()}
              >
                <Col xs={11} className="align-self-center">
                  <h5>
                    Circuito Educadores #22K,Ciudad Satelite 53100 Estado de
                    México, México
                  </h5>
                </Col>
                <Col xs={1}>
                  <div className="chevron-container">
                    <img src={chevron} alt="arrow" />
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      <div className={`map-overlay ${isOpen ? "open-map" : ""}`}>
        <ReactGA.OutboundLink
          eventLabel="Maps"
          to="https://goo.gl/maps/JukmTHDBP3EFpWwA7"
          target="_blank"
          trackerNames={["Links"]}
          style={{
            color: "white",
          }}
        >
          <div
            className="map-image"
            style={{
              backgroundImage: `url(${maps})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          />
        </ReactGA.OutboundLink>
        <Button onClick={() => toggleClass()}>X</Button>
      </div>
    </div>
  );
};

export default Contact;
