import img1 from "Pages/Images-Facial/img1.jpg";
import img2 from "Pages/Images-Facial/img2.jpg";
import img3 from "Pages/Images-Facial/img3.jpg";
import img4 from "Pages/Images-Facial/img4.jpg";
import img5 from "Pages/Images-Facial/img5.jpg";
import img6 from "Pages/Images-Facial/img6.jpg";
import img7 from "Pages/Images-Facial/img7.jpg";
import img8 from "Pages/Images-Facial/img8.jpg";
import img9 from "Pages/Images-Facial/img9.jpg";
import img10 from "Pages/Images-Facial/img10.jpg";
import img11 from "Pages/Images-Facial/img11.jpg";
import img12 from "Pages/Images-Facial/img12.jpg";
import img13 from "Pages/Images-Facial/img13.jpg";
import img14 from "Pages/Images-Facial/img14.jpg";
import img15 from "Pages/Images-Facial/img15.jpg";
import img16 from "Pages/Images-Facial/img16.jpg";
import img17 from "Pages/Images-Facial/img17.jpg";
import img18 from "Pages/Images-Facial/img18.jpg";
import img19 from "Pages/Images-Facial/img19.jpg";
import img20 from "Pages/Images-Facial/img20.jpg";
import img21 from "Pages/Images-Facial/img21.jpg";
import img22 from "Pages/Images-Facial/img22.jpg";
import img23 from "Pages/Images-Facial/img23.jpg";
import img24 from "Pages/Images-Facial/img24.jpg";
import img25 from "Pages/Images-Facial/img25.jpg";
import img26 from "Pages/Images-Facial/img26.jpg";
import img27 from "Pages/Images-Facial/img27.jpg";
import img28 from "Pages/Images-Facial/img28.jpg";
import img29 from "Pages/Images-Facial/img29.jpg";
import img30 from "Pages/Images-Facial/img30.jpg";
import img31 from "Pages/Images-Facial/img31.jpg";
import img32 from "Pages/Images-Facial/img32.jpg";

// eslint-disable-next-line
export default [
  [img1, img2, img3, img4, img5, img6, img7, img28, img29],
  [img8, img9, img10, img11, img11],
  [img12, img13, img14, img15, img16],
  [img17, img18, img19, img20, img21, img22, img30],
  [img27],
  [img32],
  [img23, img24, img25],
  [img31],
  [img26],
];
