export const data = [
  {
    title: "CORTE",
    data: [
      "Corte para Dama",
      "Corte para Caballero",
      "Shampoo, secado & Estilizado",
    ],
  },
  {
    title: "MANICURE & PEDICURE",
    data: [
      "Manicure",
      "Pedicure",
      "Gelish en manos & pies",
      "Cambio de esmalte en manos & pies",
      "Uñas acrílico",
      "Retoque acrílico",
      "Parafina",
    ],
  },
  {
    title: "COLOR",
    data: [
      "Tinte",
      "Luces",
      "Transparencias",
      "Baño de Color",
      "Matiz",
      "Contrastes",
      "Espejos",
      "Extracción de color",
      "Balayage",
      "Babylights",
      "Beachy hair",
    ],
  },
  {
    title: "DEPILACIÓN",
    data: [
      "Rostro: mentón, mejillas, patilla, bigote, diseño y depilación de ceja",
      "Área de entrepierna, línea de bikini o cavado francés",
      "Axila",
      "Media pierna",
      "Pierna",
      "Barba",
      "Nariz",
    ],
  },
  {
    title: "PEINADO",
    data: [
      "Brushing",
      "Tenaza & plancha",
      "Chongo",
      "Trenza",
      "Peinado de Novia",
      "Peinado XV años",
    ],
  },
  {
    title: "PESTAÑAS",
    data: [
      "Extensiones de pestañas 1x1",
      "Retoque de pestañas",
      "Lifting de pestañas",
      "Tinte en pestañas",
    ],
  },
  {
    title: "TRATAMIENTOS CAPILARES",
    data: [
      "Ampolleta (Hidratante, Reestructurante o Brillo intenso)",
      "Jojoba",
      "Tratamiento chemestri",
      "Brazilian Blowout",
      "Alaciado progresivo",
      "Splits ends",
      "Keratina brazileña",
      "Heat cure",
    ],
  },
  {
    title: "MAQUILLAJE",
    data: [
      "Maquillaje de día & de noche",
      "Maquillaje ojos",
      "Maquillaje de novia & XV años",
      "Maquillaje social",
    ],
  },
  {
    title: "CEJAS",
    data: [
      "Diseño de Ceja",
      "Laminado de cejas",
      "Microblading",
      "Microshading",
    ],
  },
];
