import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/adiposhape.png";
import imgp from "Pages/Images-Tecnology/adiposhape-pic.png";
import maquina from "Pages/Images-Tecnology/adiposhape-machine.png";

const Adipo = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" style={{}} />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" />
          </Col>
          <Col
            xs={12}
            style={{
              marginTop: "3em",
            }}
          >
            <div
              style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <h5>DESPÍDETE DE LA GRASA ACUMULADA</h5>{" "}
              <h5>BIENVENIDO VIENTRE PLANO</h5>
            </div>
          </Col>
          <Row className="inner-tech-text">
            <Col className="inner-tech-col" xs={{ order: 2, size: 12 }} md={{order: 1, size: 4}}>
              <h5>
                <b>MOLDEA Y ESTILIZA LA SILUETA</b>
              </h5>
              <p>
                Con Adiposhape, lucirás una nueva figura más joven, más firme,
                más ajustada a tu contorno corporal.
              </p>
              <p>
                El método NSX, permite moldear tus formas con sus{" "}
                <b>tres acciones principales,</b> logrando una silueta más
                bella.
              </p>
              <img src={imgp} alt="Fotografia de caso" />
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{order: 2, size: 4}}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col className="inner-tech-col" xs={{ order: 3, size: 12 }} md={{order: 3, size: 4}}>
              <h5>
                <b>REAFIRMA LA PIEL</b>
              </h5>
              <p>
                La piel se tonifica, se desinflama. Recupera su elasticidad. En
                definitiva, luce más joven y, sobre todo, sana.
              </p>
              <h5>
                <b>CAMBIA DE TALLA</b>
              </h5>
              <p>
                Elimina la grasa acumulada en abdomen y piernas. Los resultados
                se traducen en una perdida de volumen al "vaciar" las células
                grasas y favorece la eliminación de líquidos para disminuir el
                peso corporal.
              </p>
              <h5>
                <b>MEJORA LA CIRCULACIÓN SANGUÍNEA</b>
              </h5>
              <p>
                Actúa a la vez sobre el sistema circulatorio mejorándolo. Y lo
                hace desde dentro, ayudando a que nutra mejor a las células del
                cuerpo.
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default Adipo;
