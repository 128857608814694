import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { useIdleTimer } from 'react-idle-timer'

import { Link } from "react-router-dom";
import useWindowDimensions from "./useWindowDimensions";

var ReactGA = require("react-ga");

const Bannercastroso = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [imgSize, setSize] = useState({ height: 0, width: 0 });
  const [linkus, setLinkus] = useState({to: "", eventLabel: "", tracker: []});
  const [useGA, setGA] = useState(false);

  const {height} = useWindowDimensions();


  const toggleClass = () => {
    setOpen(!isOpen);
  };

  const showNagModal = () => {
    setOpen(true);
  };
  const onIdle = () => {
    showNagModal();
  };

  useEffect(() => {
    fetch("/magic_image.jpg")
      .then((response) => response.blob())
      .then((res) => {
        var url = URL.createObjectURL(res);
        var img = new Image();

        img.onload = function () {
          setSize(
            {
              width: (height * img.width) / img.height,
              height: height
            }
          )

          fetch("/magic_routes.json")
            .then((response) => response.json())
            .then((res) => {
              setLinkus(res);
              
              const localURL = [
                "/",
                "/contact",
                "/medicina-estetica",
                "/medicina-estetica-facial",
                "/medicina-estetica-corporal",
                "/therapy",
                "/beauty-lounge",
                "/technology",
              ];
              
              if(localURL.includes(res.to))
              {
                setGA(false);
              }
              else{
                setGA(true);
              }
          })
          URL.revokeObjectURL(img.src);
        };
        img.src = url;
      });
  }, [height]);

  useState(() => {
    
  }, [linkus]);

  useIdleTimer({ onIdle, timeout: 3500 });

  return (
    <div className={`float ${isOpen ? "open-map" : ""}`} style={{width: imgSize.width}}>
      
      {
        useGA === false && (
          <Link to={linkus.to}>
            <div
              className="map-image"
              style={{
                backgroundImage: `url(/magic_image.jpg)`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
          </Link>
        )
      }
      {
        useGA === true && (
          <ReactGA.OutboundLink
            eventLabel={linkus.eventLabel}
            to={linkus.to}
            target="_blank"
            trackerNames={linkus.tracker}
          >
            <div
              className="map-image"
              style={{
                backgroundImage: `url(/magic_image.jpg)`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
          </ReactGA.OutboundLink>
        )
      }
      
      <Button onClick={() => toggleClass()} style={{border: "none!important", boxShadow: "none!important"}}>X</Button>
    </div>
  );
};

export default Bannercastroso;
