import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/laser-crystal-3d.png";
import maquina from "Pages/Images-Tecnology/laser-crystal-3d-machine.png";

const LaserCristal = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" style={{}} />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" />
          </Col>
          <Col
            xs={12}
            style={{
              marginTop: "3em",
            }}
          >
            <div
              style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              {/* <h1>LASER CRYSTAL 3D </h1> */}
            </div>
          </Col>
          <Row className="inner-tech-text">
            <Col
              className="inner-tech-col"
              xs={{ order: 2, size: 12 }}
              md={{ order: 1, size: 4 }}
            >
              <h5>
                <b>LASER CRYSTAL 3D </b>
              </h5>
              <p>
                Equipo de IPL que cuenta con tecnología SHR (Super Hair
                Removal), permitiendo realizar 10 disparos continuos para
                facilitar la aplicación y reducir el tiempo de sesión. Trabaja
                con un cabezal a -2ºC, por lo que es prácticamente indoloro para
                el paciente.
              </p>
              <p>
                Mezcla los beneficios de las 3 longitudes de onda más efectivas
                del mercado: Diodo, Alexandrita y Nd-Yag, logrando resultados
                sumamente efectivos.
              </p>
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col
              className="inner-tech-col"
              xs={{ order: 3, size: 12 }}
              md={{ order: 3, size: 4 }}
            >
              <h5><b>Beneficios:</b></h5>
              <ul>
                <li>Eficacia clínicamente comprobada </li>
                <li>Espectro extra de emisión para depilación láser</li>
                <li>Procedimientos más rápidos, seguros y placenteros </li>
                <li>Para todo tipo de pieles, incluso bronceadas </li>
                <li>Perfecto para cualquier área del cuerpo </li>
                <li>Tecnología con los mejores resultados del mercado </li>
                <li>Sistema de refrigeración CryoCooling</li>
              </ul>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default LaserCristal;
