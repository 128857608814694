export const medicinaEsteticaData = {
  rejuvenecedores: {
    title: "TRATAMIENTOS\nREJUVENECEDORES",
    data: [
      {
        title: "Aplicación de toxina botulínica",
        desc: "Tratamiento médico con el que logramos la relajación de los músculos del rostro, consiguiendo suavizar la mirada, rejuvenecerla y reducir la formación de más arrugas.",
        session: "1 sesión cada 6 meses",
        time: "Tiempo estimado del procedimiento 50 min",
      },
      {
        title: "Look fresh con toxina botulínica",
        desc: "Si se desea lucir un rostro fresco y natural, se puede aplicar una pequeña dosis de toxina botulínica para eliminar las arrugas de expresión, refrescar el aspecto y lograr un look juvenil.",
        session: "1 sesión cada 6 meses",
        time: "Tiempo estimado del procedimiento 50 min",
      },
      {
        title:
          "Aplicación de ácido hialurónico en nasogeneanos y líneas marioneta",
        desc: "El nasogeneano y las líneas de marioneta o de la sonrisa son uno de los principales motivos de preocupación relacionados con el envejecimiento. Estas arrugas en torno a la mejilla y labios aparecen en el rostro y crean un efecto de tristeza en la expresión, a causa, sobre todo, del paso del tiempo. La aplicación de ácido hialurónico reticulado es la mejor opción para desaparecerlas.",
        session: "1 sesión cada 9 meses",
        time: "Tiempo estimado del procedimiento 50 min",
      },
      {
        title: "ÁCIDO HIALURÓNICO PARA HIDRATACIÓN",
        desc: "La aplicación de ácido hialurónico no reticulado sobre el rostro se realiza mediante microneedling con la finalidad de aportar a la piel una mayor hidratación, elasticidad y capacidad de regeneración del fibroblasto, combinado normalmente con vitaminas A, vitaminas C y células madre.",
        session:"3 sesiones - 1 sesión por semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "LIFTING FACIAL CON ÁCIDO HIALURÓNICO",
        desc: "Es un procedimiento de rejuvenecimiento facial sin cirugía que consiste en aplicar ácido hialurónico según la zona del rostro a tratar, de forma que los tejidos profundos recuperen volumen, elasticidad, firmeza e hidratación, dando un aspecto descansado y fresco.",
        session:"Duración: 10 meses",
        time: "Tiempo estimado del procedimiento 90 min",
      },
      {
        title: "VOLUMETRÍA FACIAL Y LIFTING CON REVERSAL",
        desc: "Regresa al rostro el volumen que ha perdido con el transcurso de los años. Se utiliza un relleno híbrido que estimula la síntesis de colágeno en las células de la piel, envía señales al cuerpo sobre regeneración y activa la curación y el rejuvenecimiento. Las áreas a tratar son los surcos nasogeneanos, líneas de marionetas, los ojos, pómulos, mentón, manos y glúteos. En conjunto, todas estas zonas pueden dar una apariencia agradable y juvenil.",
        session:"Duración: 18 meses",
        time: "Tiempo estimado del procedimiento 60 min ",
      },
      {
        title: "Volumetría facial y lifting con Radiesse",
        desc: "El Radiesse es hidroxiapatita cálcica que actúa recuperando el volumen perdido por causa de la edad o una pérdida de peso, ayuda a definir el contorno facial y corregir pequeñas imperfecciones del rostro.\nEste relleno dérmico atenúa las arrugas y pliegues aportando volumen de manera inmediata y estimulando la producción de colágeno natural. Siendo por ello uno de los productos más adecuados para tratar la flacidez del rostro sin perder nuestra expresión natural del rostro.\n\nBeneficios: Rejuvenecimiento facial integral, Recuperación de volumen perdido, Resultados inmediatos.",
        session: "Duración: 6-8 meses",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Aumento de labio con ácido hialurónico",
        desc: "Nuestro tratamiento logrará aumento en los labios del paciente, perfilarlo, hidratarlo, darle una mayor proyección, marcar el arco de cupido, corregir asimetrías o simplemente añadir un poco más de volumen.",
        session: "1 sesión cada 9 meses",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title:
          "Rejuvenecimiento del tercio superior del rostro con toxina botulínica y ácido hialurónico.",
        desc: "Nuestro tratamiento va enfocado a tratar y disminuir la aparición de arrugas. Las arrugas de expresión que aparecen en el tercio superior de la cara (frente, entrecejo y patas de gallo). La combinación ideal de toxina botulínica relaja los músculos y la aplicación de ácido hialurónico hidrata y elimina las líneas marcadas por la expresión del rostro.",
        session: "1 sesión cada 6 meses",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Hilos tensores",
        desc: "Su aplicación rejuvenece el rostro; elimina líneas de expresión y flacidez facial, redefiniendo el óvalo de la  cara y el contorno de la mandíbula. Consigue un efecto lifting sin necesidad de pasar por el quirófano.",
        session: "Durabilidad: de 8 a 12 meses",
        time: "Tiempo estimado del procedimiento: 60 min."
      },
    ],
  },
  remodelantes: {
    title: "TRATAMIENTOS\nREMODELANTES",
    data: [
      {
        title: "ENZIMAS PB SERUM LOW PARA PERFILAMIENTO FACIAL",
        desc: "Enzimas recombinantes inyectables de última generación basadas en ácido hialurónico que mejoran significativamente la formación de la fibrosis por la acción anti adhesiva y protectora del ácido hialurónico sobre las fibras colágenas en formación.\nHA 1.5 LOW se usa como coadyuvante en el tratamiento para perfilamiento facial: Contiene la misma cantidad de Colagenasa (Reafirma la piel), Lipasa (Elimina la grasa acumulada) y Liasa (Drena zonas con líquidos).",
        session:"1 sesión cada tercer semana",
        time: "Tiempo estimado del procedimiento 60 min ",
      },
      {
        title: "ENZIMAS PB SERUM MEDIUM PARA DOBLE MENTÓN, GRASA CORPORAL Y CELULITIS",
        desc: "Enzimas recombinantes inyectables de última generación basadas en ácido hialurónico que mejoran significativamente la formación de la fibrosis por la acción anti adhesiva y protectora del ácido hialurónico sobre las fibras colágenas en formación.\nHA 1.5 MEDIUM se usa como coadyuvante en el tratamiento para doble mentón, adiposis corporal y celulitis: Contiene la misma cantidad de Colagenasa (Reafirma la piel) y Liasa (Drena zonas con líquidos) y una cantidad mayor de Lipasa (Elimina la grasa acumulada)",
        session:"1 sesión cada tercer semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "ENZIMAS PB SERUM HIGH PARA CICATRICES Y FIBROSIS POSTQUIRÚRGICA",
        desc: "Enzimas recombinantes inyectables de última generación basadas en ácido hialurónico que mejoran significativamente la formación de la fibrosis por la acción anti adhesiva y protectora del ácido hialurónico sobre las fibras colágenas en formación.\nHA 1.5 HIGH se usa como coadyuvante en el tratamiento para cicatrices y fibrosis postquirúrgica: Contiene la misma cantidad de Lipasa (Elimina la grasa acumulada) y Liasa (Drena zonas con líquidos) y una cantidad mayor de Colagenasa (Reafirma la piel).",
        session:"1 sesión cada tercer semana",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "LIPOPAPADA ENZIMÁTICA",
        desc: "Consiste en microinyecciones de diferentes enzimas que son infiltradas en el tejido adiposo a nivel de la papada.\nLas enzimas son moléculas de naturaleza proteica que existen de forma natural en nuestro cuerpo y que mantienen el 100% de sus propiedades alcanzando los más altos estándares de pureza.\n\nAl combinarlas, se obtiene un tratamiento altamente efectivo para reducir significativamente la grasa acumulada en zonas específicas. Reduce la grasa localizada en la papada y disminuye el doble mentón, para lucir un rostro más perfilado, con el borde mandibular más marcado.",
        session:"2 sesiones, 1 sesión cada semana",
        time: "Tiempo estimado del procedimiento 30 - 40 min",
      },
      {
        title: "BICHECTOMIA ENZIMÁTICA",
        desc: "Procedimiento por el cual, a través de enzimas quemadoras de grasa localizada, se obtiene la destrucción del tejido adiposo localizado en pequeñas zonas del rostro (Mejillas). Estos agentes quemadores ayudan a la transferencia de ácidos grasos y a la formación de nuevas fibras de colágeno.",
        session:"2 sesiones, 1 sesión cada semana",
        time: "Tiempo estimado del procedimiento 30 – 40 min",
      },
      {
        title: "RINOMODELACIÓN",
        desc: "Consiste en la infiltración de material absorbible, el cual permite corregir pequeñas imperfecciones de la nariz, mejorar posibles asimetrías y mejorar estéticamente el caballete. Se lleva a cabo mediante microinyecciones de distintas sustancias de relleno, entre las cuales de uso más común el ácido hialurónico reticulado y la hidroxiapatita de calcio.",
        session:"Duración: 10-12 meses",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "MESOTERAPIA CORPORAL CON LIPOLÍTICOS",
        desc: "Ingredientes cruciales de acción diurética y desintoxicante para acelerar la circulación sanguínea, metabolizar y estimular la liberación de grasa almacenada. Especialmente indicados como apoyo para prevenir y reducir la aparición de celulitis, son un excelente coadyuvante en el control de peso. Ayuda a estimular el sistema de drenaje linfático y tonificar la masa muscular, eliminando y quemando grasa.",
        session:"Recomendado mínimo 4 sesiones",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "MESOTERAPIA CORPORAL CON LIPOENZIMAS",
        desc: "Enzimas recombinantes que combaten la retención de líquidos y potencializan la reafirmación de la piel. Estas enzimas rompen el adipocito (célula grasa) en pequeñas moléculas para ser absorbidas por el cuerpo y desechadas de una manera más fácil y rápida.",
        session:"Recomendado mínimo 4 sesiones",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "AUMENTO DE GLÚTEOS, CADERAS O PANTORRILLAS CON ÁCIDO HIALURÓNICO",
        desc: "El relleno corporal viscoelástico altamente purificado es la mejor solución para reponer el volumen corporal y corregir la forma de los glúteos, caderas y pantorrillas, esta fórmula tecnológicamente avanzada no contiene componentes animales, eliminando el riesgo de reacciones alérgicas. El ácido hialurónico en este producto no contiene endotoxinas, asegura un efecto más duradero, suaviza la piel y combate la celulitis, procedimiento sin incisiones ni cicatrices.",
        session:"Duración: 18-24 meses",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "PEPTONAS BRASILEÑAS",
        desc: "Son nutrientes naturales, que aportan sustancias fundamentales para la reparación y desarrollo celular de los músculos.\nAplicación corporal específicamente en glúteo para dar tono y volumen. Modo de aplicación micro infiltración intradérmica.",
        session: "Duración: 8-12 meses",
        time: "1 sesión semanal durante 8 semanas"
      },
    ]
  },
  regenerativos: {
    title: "TRATAMIENTOS\nREGENERATIVOS",
    data: [
      {
        title: "CÉLULAS MADRE DREMAL AGF CON DERMAPEN",
        desc: "Innovador tratamiento dermal que, a través de extractos proteicos de células madre de placenta, regenera a nivel celular maximizando los efectos revitalizantes de la piel. Las células madre favorecen el metabolismo celular; activando la producción de colágeno y elastina, disminuyendo significativamente la longitud y profundidad de arrugas, manchas y secuelas de acné. Su aplicación con dermapen favorece la penetración profunda de los principales activos, haciendo una abrasión controlada.",
        session: "Sesiones recomendadas: 6, 1 sesión cada mes",
        time: "Tiempo estimado del procedimiento: 60 min."
      },
      {
        title: "TRATAMIENTO CAPILAR CON CÉLULAS MADRE AGF CON DERMAPEN",
        desc: "Tratamiento capilar que consiste en hacer pequeñas micropunciones para depositar nutrientes activos (células madre, aminoácidos y vitaminas B) en el cuero cabelludo y folículo piloso, promoviendo nueva formación de cabello. Para así lograr un gran efecto mediante una técnica mínimamente invasiva y potenciar el crecimiento del cabello, nutrición y aumentar su fortaleza debido a la focalización del producto en la zona necesaria.",
        session:"Sesiones recomendadas: 6 (Dependiendo valoración médica) - 1 sesión cada mes",
        time: "Tiempo estimado del procedimiento 60 min",
      },
      {
        title: "Plasma rico en factores de crecimiento",
        desc: "Procedimiento de Medicina estética, que rejuvenece tu rostro y otras zonas de la piel. Es un tratamiento indoloro, con el cual se inyectan una serie de proteínas plasmáticas en tu piel para potenciar la regeneración celular y el rejuvenecimiento. El plasma inyectado proviene de tu propio organismo, por lo que no hay riesgo alguno de rechazo. Y, por supuesto, es un tratamiento ambulatorio de fácil aplicación. Tras su aplicación, puedes hacer tu vida cotidiana con total normalidad, ya que no necesita hospitalización.",
        session: "Paquete de 3 sesiones",
        time: "Tiempo estimado del procedimiento 90 min",
      },
      {
        title: "TRATAMIENTO ÍNTIMO DESPIGMENTANTE AGF PINK",
        desc: "Tratamiento despigmentante, que actúa y participa directamente en el proceso de regulación de melanina, evitando y reduciendo las manchas e hiperpigmentaciones de la piel, con un amplio espectro de aplicación incluyendo el área intima.\n\nPotencializa la regeneración cutánea con cultivos de células madre mesenquimales restaurando la piel y regulando la producción de pigmento con el objetivo de revitalizar y mejorar la apariencia en zonas como: Labios, axilas, codos, rodillas, entrepierna, manchas localizadas en rostro, hiperpigmentación por cicatrices de secuela de acné y zona genital.",
        session:"Recomendado de 4-6 sesiones",
        time: "Tiempo estimado del procedimiento 45 min",
      },
      {
        title: "REGENERACIÓN DE ÓRGANOS Y TEJIDOS CON CÉLULAS MADRE VIVAS",
        desc: "Es un procedimiento poco invasivo, indoloro, no quirúrgico y de rápida recuperación en el cual se aplica células madre vivas por vía intravenosa para regenerar órganos y restaurar, mantener y mejorar tejidos dañados en el cuerpo por el paso del tiempo.",
        session:"Sesiones recomendadas dependiendo valoración médica",
        time: "Tiempo estimado del procedimiento 90 min",
      },
      {
        title: "PEELINGS",
        desc: "Es un tratamiento facial no invasivo para tratar diferentes afecciones en la piel como:\nº Despigmentante\nº Cicatrices de acné, aclaramiento y flacidez\nº Reducción de líneas de expresión y/o envejecimiento prematuro\nº Purificante para pieles grasas",
        session:"Recomendado de 4-6 sesiones - 1 sesión cada 15 días",
        time: "Tiempo estimado del procedimiento 30 min",
      },
    ]
  }
};
