import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/uld-logo.png";
import maquina from "Pages/Images-Tecnology/ultrasonido-machine.png";

const Ultrasonido = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" style={{}} />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" style={{width: "250px!important"}} />
          </Col>
          {/* <Col
            xs={12}
            style={{
              marginTop: "3em",
            }}
          >
            <div
              style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <h1>APARATOLOGÍA ULTRASONIDO PROFESIONAL / ULD 03 </h1>
            </div>
          </Col> */}
          <Row className="inner-tech-text">
            <Col
              className="inner-tech-col"
              xs={{ order: 2, size: 12 }}
              md={{ order: 1, size: 4 }}
            >
              <h5>
                <b>APARATOLOGÍA ULTRASONIDO PROFESIONAL / ULD 03 </b>
              </h5>
              <p>
                Tratamiento cuya acción sobre los tejidos causa un micro-masaje
                molecular, logrando efectos terapéuticos. Ya que los tejidos de
                nuestro cuerpo poseen propiedades elásticas, se logra un
                excelente desplazamiento de las ondas ultrasónicas.
              </p>
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col
              className="inner-tech-col"
              xs={{ order: 3, size: 12 }}
              md={{ order: 3, size: 4 }}
            >
              <h5><b>Beneficios:</b></h5>
              <ul>
                <li>Tratamiento no invasivo y sin molestias.</li>
                <li>
                  Mejora la circulación sanguínea y es excelente para la
                  relajación muscular, efecto analgésico.
                </li>
                <li>
                  Permite un alisado de la piel, logrando cambiar la
                  consistencia de las células de grasa.{" "}
                </li>
                <li>
                  Reduce la acumulación de grasa localizada, tonifica y oxigena
                  la piel. Para el tratamiento de várices, retención de líquidos
                  y post operatorios.{" "}
                </li>
                <li>
                  Logra la dilatación de los folículos pilosos y las glándulas
                  sudoríparas; generando procesos con efectos fisiológicos y
                  terapéuticos de beneficio para el cuerpo.
                </li>
              </ul>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default Ultrasonido;
