import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/sirio.png";
import maquina from "Pages/Images-Tecnology/sirio-machine.png";

const Sirio = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" style={{}} />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" />
          </Col>
          <Col
            xs={12}
            style={{
              marginTop: "3em",
            }}
          >
            <div
              style={{
                width: "50%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <h1>SIRIO</h1>
            </div>
          </Col>
          <Row className="inner-tech-text">
            <Col
              className="inner-tech-col"
              xs={{ order: 2, size: 12 }}
              md={{ order: 1, size: 4 }}
            >
              <h5>
                <b>SIRIO</b>
              </h5>
              <p>
                Equipo auxiliar de entrenamiento físico y estimulación
                focalizada. Promueve la reafirmación de la piel, el
                fortalecimiento y tonificación de los músculos, mejorando la
                apariencia estética. Utiliza contracciones que llegan a través
                de aplicadores colocados, suave y superficialmente, en el área
                del músculo objetivo.
              </p>
              <p>
                Se realiza el equivalente a un entrenamiento de alta intensidad,
                sin dolor.
              </p>
              <p>
                La sesión dura aproximadamente 30 minutos y no requiere tiempo
                de recuperación. Se necesitan sólo de 2 a 3 sesiones por semana
                para poder observar mejoras en la mayoría de las personas.
              </p>
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{ order: 2, size: 4 }}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col
              className="inner-tech-col"
              xs={{ order: 3, size: 12 }}
              md={{ order: 3, size: 4 }}
            >
              <h5><b>Beneficios:</b></h5>
              <ul>
                <li>Tonifica</li>
                <li>Fortalece</li>
                <li>Reafirma</li>
                <li>Aumenta fibras musculares</li>
              </ul>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default Sirio;
