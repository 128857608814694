import React from "react";
import styled from "styled-components";
import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline, ScrollTrigger } from "react-gsap";

const ParallaxStyled = styled.div`
  .DarkFill {
    min-height: 100vh;
    width: 100vw;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    background: rgb(72, 70, 82);
    background: linear-gradient(
      90deg,
      rgba(72, 70, 82, 1) 0%,
      rgba(54, 52, 56, 1) 100%
    );
    img {
      width: 100%;
      height: auto;
      position: absolute;
    }
  }

  .LightFill {
    min-height: 100vh;
    width: 100vw;
    max-width: 100%;
    position: relative;
    overflow: hidden;
    background: rgb(96, 99, 111);
    background: linear-gradient(
      221deg,
      rgba(96, 99, 111, 1) 0%,
      rgba(189, 199, 213, 1) 100%
    );
    img {
      width: 100%;
      height: auto;
      position: absolute;
    }
  }
`;

const Parallax = (props) => (
  <ParallaxStyled>
    <Controller>
      <Scene duration="200%" triggerHook="onEnter">
        <Timeline wrapper={<div className={props.className} />}>
          <Tween
            position="0"
            from={{
              yPercent: -50,
            }}
            to={{
              yPercent: 0,
            }}
          >
            <div
              className={props.className === "LightFill" ? "background-men background-parallax-image " : " background-left background-parallax-image "}
              style={{
                backgroundImage: `url(${props.image})`,
              }}
            />
            <div
              className={props.className === "LightFill" ? "background-lighten" : "background-darken"}
            />
          </Tween>
          <ScrollTrigger start="0% center" end="30% center" scrub={0.5} ease="Power2.easeIn">
            <Tween
              position="0"
              from={{
                opacity: "0%",
              }}
              to={{
                opacity: "100%",
              }}

            >
              <div className="inner-parallax-banner">{props.children}</div>
            </Tween>
          </ScrollTrigger>
        </Timeline>
      </Scene>
    </Controller>
  </ParallaxStyled>
);

export default Parallax;
