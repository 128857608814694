import leaf from "Landing/Images/leaf.png";
const Separator = (props) => {
  return (
    <div className="separator">
      <div className="inner-separator">{props.children}</div>
      <div className="leaf-container-left separator-img-l">
        <img src={leaf} alt="Flecha" />
      </div>
      <div className="leaf-container-right separator-img-r">
        <img src={leaf} alt="Flecha" />
      </div>
    </div>
  );
};

export default Separator;
