import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/NOVAPRESS.png";
import imgp from "Pages/Images-Tecnology/novapress-pic.png";
import maquina from "Pages/Images-Tecnology/novapress-machine.png";

const Nova = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" />
          </Col>
          <Col
            xs={12}
            style={{
              marginTop: "3em",
            }}
          >
            <div
              style={{
                width: "30%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <h5>LA ÚLTIMA GENERACIÓN EN PRESOTERAPIA</h5>
            </div>
          </Col>
          <Row className="inner-tech-text">
            <Col className="inner-tech-col" xs={{ order: 2, size: 12 }} md={{order: 1, size: 4}}>
              <p>
                La máquina de presoterapia de Novasonix NOVApress Smart,
                facilita la eliminación de los líquidos, grasas y toxinas,
                mejora la eliminación de la celulitis, los edemas, las varices y
                los detritus que se hayan producido por otras intervenciones,
                además de prevenir futuras formaciones.
              </p>
              <img src={imgp} alt="Fotografia de caso" />
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{order: 2, size: 4}}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col className="inner-tech-col" xs={{ order: 3, size: 12 }} md={{order: 3, size: 4}}>
              <p>
                Esta máquina de presoterapia actúa a través de 6 modos
                operativos a elegir según la presión de las diferentes cámaras:
                <ul>
                  <li>Prelinfático</li>
                  <li>Linfático</li>
                  <li>Clínico</li>
                  <li>Circulatorio</li>
                  <li>Deportivo</li>
                  <li>Embarazadas</li>
                </ul>
              </p>
              <h5>
                <b>TECNOLOGÍA SMART</b>
              </h5>
              <p>
                Gracias a su pantalón compuesto por 24 cámaras en forma de
                “escamas de pez” se obtienen resultados garantizados.
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default Nova;
