import img1 from "Pages/Images-Therapy/img1.jpg";
import img2 from "Pages/Images-Therapy/img2.jpg";
import img3 from "Pages/Images-Therapy/img3.jpg";
import img4 from "Pages/Images-Therapy/img4.jpg";
import img5 from "Pages/Images-Therapy/img5.jpg";
import img6 from "Pages/Images-Therapy/img6.jpg";
import img7 from "Pages/Images-Therapy/img7.jpg";
import img8 from "Pages/Images-Therapy/img8.jpg";
import img9 from "Pages/Images-Therapy/img9.jpg";
import img10 from "Pages/Images-Therapy/img10.jpg";
import img11 from "Pages/Images-Therapy/img11.jpg";
import img12 from "Pages/Images-Therapy/img12.jpg";
import img13 from "Pages/Images-Therapy/img13.jpg";
import img14 from "Pages/Images-Therapy/img14.jpg";

// eslint-disable-next-line
export default [
  img1,
  img2,
  img3,
  img4,
  img6,
  img7,
  img8,
  img5,
  img9,
  img10,
  img11,
  img12,
  img13,
  img14,
];