import React, { useState, useEffect } from "react";
import "Landing/landing.css";
import { Helmet } from "react-helmet";

import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

import Hero from "Landing/Partials/Hero";
import MyParallaxBanner from "Landing/Partials/MyParallaxBanner";
import Separator from "Landing/Partials/Separator";
// import CarrouselFMV from "Landing/Partials/CarrouselFMV";

import branding from "Landing/Images/lavie-bco.png";
import Men from "Landing/Images/Men.png";
import Woman from "Landing/Images/woman_trimmed.png";
import background_image from "Landing/Images/background_landing.jpg";

import contacto from "assets/agendacita.svg";
import background from "Landing/Images/background-negro.jpg";
import whatsapp from "Landing/Images/whatsapp.png";
import facebook from "Landing/Images/facebook.png";
import instagram from "Landing/Images/instagram.png";
import tiktok from "Landing/Images/tiktok.png";
import youtube from "Landing/Images/youtube.png";
import pinterest from "Landing/Images/pinterest.png";
import botonayuda from "Landing/Images/botonayuda.png";
import footer from "Landing/Images/footer.png";
import leaf from "Landing/Images/leaf.png";
import banner1 from "Landing/Images/banner1.jpg";
import banner2 from "Landing/Images/banner2.jpg";
import banner3 from "Landing/Images/banner3.jpg";

var ReactGA = require("react-ga");

const Landing = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 200);
    });
  }, []);
  return (
    <>
      <div className="main">
        <Helmet>
          <meta charSet="utf-8" />
          <title>La Vie Clinic Health &amp; Beauty</title>
          <meta
            name="description"
            content="Somos una clínica especializada en la belleza, contamos con tecnología de punta y personal calificado que ponemos a tu servicio a través de nuestras líneas de negocio: Medicina Estética, Technology y Therapy.
          ¡Es tiempo de reinventarte! "
          />
        </Helmet>
        <Hero />
        <div
          className={
            scroll
              ? "bottom-bar opacity-1 hide-mobile"
              : "opacity-1 hide-mobile"
          }
          style={{
            width: "100%",
            backgroundColor: "#3d4254",
            backgroundImage: `url(${background})`,
          }}
        >
          <div
            style={{
              paddingLeft: "10vw",
              paddingRight: "5vw",
              marginLeft: "0",
              marginRight: "0",
            }}
            className="bottomBar"
          >
            <Row className="row-bar">
              <Col
                xs={12}
                md={2}
                style={{
                  padding: "10px",
                }}
                className="text-align-center align-self-center"
              >
                <Link to="/">
                  <img
                    src={branding}
                    alt="Branding"
                    style={{
                      verticalAlign: "middle",
                      maxWidth: "100px",
                    }}
                  />
                </Link>
              </Col>
              <Col xs={12} md={2}>
                <Row className="f-h">
                  <Col xs={12} className="align-self-end">
                    <Link to="/medicina-estetica">Medicina Estética</Link>
                  </Col>
                  <Col xs={12} className="align-self-start">
                    <Link to="/technology">Tecnología</Link>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={2}>
                <Row className="f-h">
                  <Col xs={12} className="align-self-end">
                    <Link to="/therapy">Therapy</Link>
                  </Col>
                  <Col xs={12} className="mini-logos align-self-start">
                    <ReactGA.OutboundLink
                      eventLabel="Instagram"
                      to="https://www.instagram.com/lavieclinicmx/"
                      target="_blank"
                      trackerNames={["Links"]}
                    >
                      <img src={instagram} alt="Logo Instagram" />
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                      eventLabel="Facebook"
                      to="https://www.facebook.com/LaVieClinicMx"
                      target="_blank"
                      trackerNames={["Links"]}
                    >
                      <img src={facebook} alt="Logo Facebook" />
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                    eventLabel="TikTok"
                    to="https://www.tiktok.com/@lavieclinicmx"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={tiktok} alt="Logo TikTok" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Youtube"
                    to="https://www.youtube.com/@lavieclinicsatelite"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={youtube} alt="Logo Youtube" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Pinterest"
                    to="https://www.pinterest.com/LaVieClinicMX/"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={pinterest} alt="Logo Pinterest" />
                  </ReactGA.OutboundLink>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={2}>
                <Row className="f-h">
                  <Col xs={12} className="align-self-end">
                    <Link to="/contact">
                      <img
                        src={contacto}
                        style={{
                          maxWidth: "90%",
                          width: "200px",
                        }}
                        alt="Logo Contacto"
                      />
                    </Link>
                  </Col>
                  <Col xs={12} className="mini-logos align-self-start"></Col>
                </Row>
              </Col>
              <Col
                xs={6}
                md={2}
                className="align-self-center"
                style={{
                  textAlign: "right",
                }}
              >
                <ReactGA.OutboundLink
                  eventLabel="Whatsapp"
                  to="https://api.whatsapp.com/send?phone=5215629993831&text=%C2%A1Hola!%20%C2%A1Me%20interesa%20saber%20acerca%20de%20La%20Vie%20Clinic!"
                  target="_blank"
                  trackerNames={["Links"]}
                >
                  <img
                    src={botonayuda}
                    alt="Boton ayuda"
                    style={{
                      maxWidth: "100px",
                    }}
                  />
                </ReactGA.OutboundLink>
              </Col>
              <Col
                xs={6}
                md={1}
                className="align-self-center"
                style={{
                  textAlign: "left",
                }}
              >
                <ReactGA.OutboundLink
                  eventLabel="Whatsapp"
                  to="https://api.whatsapp.com/send?phone=5215629993831&text=%C2%A1Hola!%20%C2%A1Me%20interesa%20saber%20acerca%20de%20La%20Vie%20Clinic!"
                  target="_blank"
                  style={{
                    paddingLeft: "1em",
                  }}
                  trackerNames={["Links"]}
                >
                  <img
                    src={whatsapp}
                    alt="Logo Whatsapp"
                    style={{
                      maxWidth: "50px",
                      cursor: "pointer",
                    }}
                  />
                </ReactGA.OutboundLink>
              </Col>
            </Row>
          </div>
        </div>
        <MyParallaxBanner className={"DarkFill"} image={Woman}>
          <Row style={{ minHeight: "100%" }}>
            <Col xs={12} md={{ size: 3, offset: 8 }}>
              <h2
                style={{
                  color: "white",
                  fontSize: "24px",
                  fontWeight: 300,
                  textAlign: "left",
                  display: "inline-block",
                }}
              >
                Somos una clínica especializada en la belleza,{" "}
                <h2 style={{ fontSize: "32px", fontWeight: 300 }}>
                  enfocados principalmente al <br /> cuidado facial y corporal.
                </h2>
                <span>
                  contamos con especialistas altamente calificados en{" "}
                </span>
                <span>cada uno de los servicios que ofrecemos.</span>
              </h2>
            </Col>
          </Row>
        </MyParallaxBanner>
        <Separator>
          <span
            style={{
              display: "table-cell",
              verticalAlign: "middle",
            }}
          >
            <h5
              style={{
                color: "black",
                fontWeight: "100",
              }}
            >
              Evoca naturalidad con nuestro servicio de revitalización,
            </h5>
            <h5
              style={{
                color: "#40AFC2",
                fontWeight: "400",
                marginTop: "2rem",
              }}
            >
              logra el resurgimiento de la juventud, la firmeza y belleza de tu
              cuerpo y ser.
            </h5>
          </span>
        </Separator>
        <MyParallaxBanner className={"LightFill"} image={Men}>
          <Row style={{ minHeight: "100%" }}>
            <Col
              className="no-pd-mobile"
              xs={12}
              md={{ size: 5 }}
              style={{
                paddingLeft: "10em",
              }}
            >
              <h2
                style={{
                  color: "black",
                  fontSize: "28px",
                  fontWeight: 300,
                  textAlign: "right",
                }}
              >
                <h2
                  style={{
                    fontSize: "32px",
                    fontWeight: 300,
                    marginBottom: "1em",
                  }}
                >
                  Tenemos un lugar donde puedes hacer una pausa en tu vida,
                  pensado en renovar tu imagen.
                </h2>
                En La Vie Clinic podrás encontrar la mejor opción para cuidarte,
                verte y sentirte mejor. Nuestro objetivo primordial es lograr
                que nuestros clientes se sientan bien consigo mismos y logren
                transmitirlo.
              </h2>
            </Col>
          </Row>
        </MyParallaxBanner>
        <div
          className="mobile-padding-bottom-bar"
          style={{
            backgroundImage: `url(${background_image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionY: "-2em",
          }}
        >
          {/* <CarrouselFMV /> */}
          <div
            className="w-100 carrousel-slides"
            style={{
              position: "relative",
              width: "90%!important",
              marginLeft: "auto",
              marginRight: "auto"
            }}
          >
            <Row>
              <Col xs={12}>
                <div
                  className="carrousel-container"
                  style={{
                    backgroundImage: `url(${background_image})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <Row style={{
                    padding: "2em",
                    marginLeft: "0",
                    marginRight: "0"
                  }}>
                    <Col xs={12} style={{
                      padding: "0"
                    }}>
                      <Link to="/medicina-estetica">
                        <img
                          src={banner1}
                          className="zoom-img"
                          style={{
                            width: "100%",
                          }}
                          alt="Banner1"
                        />
                      </Link>
                    </Col>
                    <Col xs={12} style={{
                      padding: "0",
                      marginTop: "2em",
                      marginBottom: "2em"
                    }}>
                      <Link to="/therapy">
                        <img
                          src={banner3}
                          className="zoom-img"
                          style={{
                            width: "100%",

                          }}
                          alt="Banner2"
                        />
                      </Link>
                    </Col>
                    <Col xs={12} style={{
                      padding: "0"

                    }}>
                      <Link to="/technology">
                        <img
                          src={banner2}
                          className="zoom-img"
                          style={{
                            width: "100%",
                          }}
                          alt="Banner3"
                        />
                      </Link>
                    </Col>

                    <Col xs={12} style={{
                      padding: "0"
                    }}>
                      <Row className="w-100"
                        style={{
                          minHeight: "30vh",
                          marginLeft: "auto",
                          marginRight: "auto",
                          borderTop: "3px solid #000000",
                          borderBottom: "3px solid #000000",
                          marginTop: "2em",
                          padding: "2em",
                        }}>

                        <Col xs={12} style={{ position: "relative" }}>
                          <img
                            src={footer}
                            style={{
                              maxWidth: "100%",
                              maxHeight: "100%",
                              width: "auto",
                              height: "auto",
                              position: "absolute",
                              top: 0,
                              bottom: 0,
                              left: 0,
                              right: 0,
                              margin: "auto"
                            }}
                            alt="Footer"
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Col>

            </Row>

            <div className="leaf-container-left carr-img-l">
              <img src={leaf} alt="hoja" />
            </div>
            <div className="leaf-container-right carr-img-r">
              <img src={leaf} alt="hoja" />
            </div>
          </div>
          <div
            style={{
              paddingLeft: "calc(25vw + 2em)",
              paddingRight: "calc(25vw + 2em)",
              backgroundColor: "#5e6172",
              color: "white"
            }}
          >
            {/* <Row
              className="w-100"
              style={{
                minHeight: "50vh",
                marginLeft: "0",
              }}
            >
              <Col
                xs={12}
                md={2}
                className="valores-text hide-mobile"
                style={{
                  backgroundColor: "#3d4254",
                  minHeight: "50vh",
                  paddingLeft: "20px",
                  paddingTop: ".5em",
                  marginTop: "auto",
                  marginBottom: "auto",
                  backgroundImage: `url(${background})`,
                }}
              >
                VA
                <br />
                LO
                <br />
                RE
                <br />S
              </Col>
              <Col
                xs={12}
                md={2}
                className="valores-text show-mobile"
                style={{
                  backgroundColor: "#3d4254",
                  minHeight: "10vh",
                  padding: ".8em",
                  marginBottom: "2em",
                  backgroundImage: `url(${background})`,
                }}
              >
                VALORES
              </Col>
              <Col
                xs={12}
                md={10}
                style={{
                  minHeight: "50vh",
                }}
              >
                <Row
                  style={{
                    minHeight: "50%",
                  }}
                >
                  <Col xs={12} md={4} className="valores align-self-start">
                    <h5>CALIDAD</h5>
                    <h5>en todos nuestros productos y tratamientos.</h5>
                  </Col>
                  <Col xs={12} md={4} className="valores align-self-start">
                    <h5>Integridad</h5>
                    <h5>
                      cultivando los más altos valores morales, profesionales y
                      éticos.
                    </h5>
                  </Col>
                  <Col xs={12} md={4} className="valores align-self-start">
                    <h5>Respeto</h5>
                    <h5>para nuestro personal y nuestros clientes.</h5>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4} className="valores align-self-start">
                    <h5>Bienestar</h5>
                    <h5>al sentirse en armonía con usted mismo.</h5>
                  </Col>
                  <Col xs={12} md={4} className="valores align-self-start">
                    <h5>Compromiso</h5>
                    <h5>al hacer de su estancia la más placentera.</h5>
                  </Col>
                  <Col xs={12} md={4} className="valores align-self-start">
                    <h5>Servicio al cliente</h5>
                    <h5>excepcional y de primer nivel.</h5>
                  </Col>
                </Row>
              </Col>
            </Row> */}

            <Row style={{ paddingBottom: "2em", paddingTop: "2em" }}>
              <Col xs={12}>
                <div
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center"
                  }}
                >
                  <a href="/aviso_privacidad.pdf" style={{
                    color: "unset",
                    textDecoration: "unset"
                  }}>
                    <h5 style={{ fontWeight: 300 }}>Consulta nuestro <b style={{ fontWeight: 600 }}>Aviso de Privacidad</b></h5>
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Landing;
