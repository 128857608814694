import React, { useState } from "react";

import "Pages/pages.css";
import { Accordion } from "Pages/Partials/Accordion";
import { medicinaEsteticaData as data } from "data/MEData";

import { Helmet } from "react-helmet";
import { Row, Container, Col } from "reactstrap";

import ReactSlidy from "react-slidy";
import "react-slidy/lib/styles.css";

import tiempo from "Landing/Images/tiempo.png";
import images from "Pages/imagesClinico";

import background from "Pages/Images-Clinico/background.jpg";

const conditionalClass = (isActive, index) =>
  isActive
    ? `carrouselFMV-button-f active-carr-f`
    : `carrouselFMV-button-f non-active-carr-f`;

const containImages = (index, index_n) => {
  if(index === 2 && index_n === 3)
  {
    return "contain";
  }
  if(index === 1 && index_n === 3)
  {
    return "contain";
  }
  if(index === 1 && index_n === 5)
  {
    return "contain";
  }
  return "cover";
}


const MedicinaEstetica = (props) => {
  const TITLES = Object.keys(data).map(function (key, index) {
    return data[key].title;
  });
  const SLIDES = Object.keys(data).map(function (key, index) {
    return data[key].data;
  });

  const [actualSlide, setActualSlide] = useState(0);

  const updateSlide = ({ currentSlide }) => {
    setActualSlide(currentSlide);
  };
  return (
    <Container
      id="section-md"
      className="Section-MD"
      style={{
        minHeight: "100vh",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>La Vie Clinic Medicina Estética</title>
        <meta
          name="description"
          content="Conoce la belleza del rejuvenecimiento, nutre y dale firmeza a
                tu piel, disfruta de un rostro hidratado y libre de
                imperfecciones."
        />
      </Helmet>
      <div
        className="background-section"
        style={{
          backgroundImage: `url(${background})`,
        }}
      />
      <div className="tiempo-container">
        <img src={tiempo} alt="Top Banner" />
      </div>
      <Row className="padding-top-banner-mobile" style={{ minHeight: "100vh", paddingTop: "25vh" }}>
        <Col xs={12} style={{ paddingRight: "10vw", paddingLeft: "10vw" }}>
          <Row>
            <Col xs={12}>
              <h5>¡Obtén seguridad y bienestar con nuestros tratamientos!</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5>
                Conoce la belleza del rejuvenecimiento, nutre y dale firmeza a
                tu piel, disfruta de un rostro hidratado y libre de
                imperfecciones.
              </h5>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              style={{
                textAlign: "left",
              }}
            >
              <ul>
                <li>
                  <h5
                    style={{
                      fontSize: "15px",
                    }}
                  >
                    <b
                      style={{
                        fontWeight: "600",
                      }}
                    >
                      CLÍNICO
                    </b>
                  </h5>
                </li>
              </ul>
            </Col>
          </Row>
          <Row
            style={{
              paddingTop: "2em",
              paddingBottom: "2em",
            }}
          >
            {TITLES.map((title, index) => {
              const parts = title.split("\n");
              console.log(parts);
              return (
                <Col
                  xs={6}
                  md={4}
                  key={index}
                  className="custom-col-page"
                >
                  <button
                    key={index}
                    className={conditionalClass(index === actualSlide, index)}
                    onClick={() => updateSlide({ currentSlide: index })}
                    style={{
                      fontSize: index === 6 ? ".9rem" : "",
                      height: "100%"
                    }}
                  >
                    {title.split(/\n/).map((line, index) => index === 0 ? line : <><br/>{line}</>)}
                  </button>
                </Col>
              );
            })}
          </Row>
          <ReactSlidy
            doAfterSlide={updateSlide}
            slide={actualSlide}
            showArrows={false}
          >
            {SLIDES.map((aItem, index) => (
              <Row key={index}>
                <Col xs={12}>
                  <Accordion open={0}>
                    {aItem.map((item, index_n) => {
                      return (
                        <Accordion.Item key={index_n}>
                          <Accordion.Header>{item.title}</Accordion.Header>
                          <Accordion.Body>
                            <Row
                              style={{
                                whiteSpace: "normal",
                              }}
                            >
                              <Col
                                md={4}
                                style={{
                                  backgroundImage: `url(${images[index][index_n]})`,
                                  backgroundSize: `${containImages(index, index_n)}`,
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  marginTop: "-1em",
                                  marginBottom: "-1em",
                                  marginLeft: "-.25em",
                                  whiteSpace: "normal!important",
                                }}
                                className="image-container-accordion"
                              ></Col>
                              <Col md={8}>
                                <Row>
                                  <Col xs={12}>
                                    <div>
                                      <p style={{whiteSpace: "pre-wrap"}}>{item.desc}</p>
                                    </div>
                                  </Col>
                                  <Col xs={12}>
                                    <h6 style={{
                                      fontSize: "12px"
                                    }}>
                                      <b>{item.notes}</b>
                                    </h6>
                                  </Col>
                                  <Col xs={12}>
                                    <h6>
                                      <b>{item.pack}</b>
                                    </h6>
                                  </Col>
                                  <Col xs={12}>
                                    <h6>
                                      <b>{item.session}</b>
                                    </h6>
                                  </Col>
                                  <Col xs={12}>
                                    <h6>
                                      <b>{item.time}</b>
                                    </h6>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </Col>
              </Row>
            ))}
          </ReactSlidy>
        </Col>
      </Row>
    </Container>
  );
};

export default MedicinaEstetica;
