export const data = {
  title: "Therapy",
  data: [
    {
      title: "Terapia Corporal Relajante",
      desc: "Es una terapia manual con presión ligera a media, destinada a mejorar el bienestar de la persona ya que su máximo objetivo es aumentar la producción de endorfinas en nuestro cuerpo. El objetivo principal del Masaje Relajante es aliviar la tensión, mejorar el dolor, reducir tensiones musculares y conseguir un estado mental de relajación.",
      session: "Cuerpo completo: 50 min – 1hr (dependiendo la valoración del especialista)",
      time: "Espalda: 30 min",
    },
    {
      title: "Terapia Corporal Descontracturante",
      desc: "Es una terapia manual con presión media a fuerte, que se utiliza para relajar la musculatura y disolver las contracturas que se producen por el estrés, las malas posturas o la falta de descanso.",
      session: "",
      time: "Cuerpo completo:  1 – 1:15hr (dependiendo la valoración del especialista)",
    },
    {
      title: "Terapia Corporal Deportiva",
      desc: "Es una terapia manual con presión fuerte que prepara los tejidos para el esfuerzo deportivo, así como para la prevención y recuperación de lesiones tanto en entrenamiento como en competencias.",
      session: "",
      time: "Cuerpo completo:  1 – 1:15hr (dependiendo la valoración del especialista)",
    },
    {
      title: "Terapia Corporal de Drenaje Linfático",
      desc: "Es una terapia manual que mezcla varias técnicas con deslizamientos suaves hacia las linfas (pliegues del cuerpo como rodillas, codos y cuello) para promover la desintoxicación, evitar retención de líquidos o complementario a una liposucción.",
      session: "",
      time: "Cuerpo completo: 50 min – 1hr (dependiendo la valoración del especialista)",
    },
    {
      title: "ENVOLTURA CORPORAL CHAMPAGNE",
      desc: "Experiencia de relajación para consentirse.\n\nBeneficios: Hidratante, suavizante y antioxidante por su contenido de extracto de uva chardonnay. Revitaliza, nutre y protege la piel contra el envejecimiento.\n\nPara todo tipo de piel.",
      bulletpoints: [
        "SCRUB - Gel Exfoliante / Retira células muertas y activa la microcirculación",
        "MUD - Mascarilla / Limpia, humecta e hidrata la piel",
        "ACEITE / Deja la piel deslumbrante y sedosa",
        "CREMA / Altamente emoliente e hidratante"
      ],
      session: "",
      time: "Tiempo estimado del procedimiento: 60 min",
    },
    {
      title: "ENVOLTURA CORPORAL ANTIESTRÉS (LAVANDA & ROMERO)",
      desc: "Experiencia de relajación para descansar.\n\nBeneficios: Su agradable aroma brinda un estado de paz y relajación. Propiedades emolientes e hidratantes que dejan la piel suave y humectada.\n\nPara todo tipo de piel.",
      bulletpoints: [
        "SCRUB - Gel Exfoliante / Retira células muertas y activa la microcirculación",
        "MUD - Mascarilla / Nutre, humecta y relaja la piel",
        "ACEITE / Total suavidad y relajación",
        "CREMA / Suavizante"
      ],
      session: "",
      time: "Tiempo estimado del procedimiento: 60 min",
    },
    {
      title: "ENVOLTURA CORPORAL ESPUMA DE ÁNGEL",
      desc: "Experiencia de relajación para suavizar tu piel.\n\nBeneficios: Línea que maravillosamente promueve una piel suave, nutrida y humectada. Envoltura relajante y aromática ideal para el tratamiento Velo de Novia.\n\nPara todo tipo de piel, en especial pieles secas.",
      bulletpoints: [
        "SCRUB - Gel Exfoliante / Retira células muertas y activa la microcirculación",
        "MUD - Mascarilla / Nutre, humecta y relaja la piel",
        "ACEITE / Humectación, nutrición y suavidad",
        "CREMA / Nutritiva e hidratante"
      ],
      session: "",
      time: "Tiempo estimado del procedimiento: 60 min",
    },
    {
      title: "VAPOR & SAUNA",
      desc: "La diferencia entre una sauna y un baño de vapor se puede resumir en dos palabras: seco y húmedo. Las saunas proporcionan calor seco, mientras que los baños de vapor lo generan húmedo. Ambos abren los poros, relajan los músculos y ayudan a la relajación. Los baños de calor que ofrece el vapor y sauna, tienen efectos positivos tanto en el cuerpo como en la mente.",
      session: "",
      time: "20-30 minutos ",
    },
    {
      title: "FACIAL HYDRAFRUTAL BRILLO & ANTIEDAD",
      desc: "Tratamiento enriquecido con células madre de uva\n\nBeneficios: Ideal para pieles que manifiestan los primeros signos del envejecimiento, como resequedad y líneas de expresión. Línea altamente hidratante y humectante que brinda elasticidad, tono y brillo a la piel.\nRemineraliza la piel evitando la pérdida de agua y ayuda en la regeneración celular.\n\nPara piel normal a seca\n\nSUAVE AROMA: Cítrico\n\nSENSACIÓN: Humectación profunda.\n\nCombinado con ultrasonido facial que atenúa las manchas de la piel y líneas de expresión, reafirma el rostro, desinflama la piel, elimina toxinas y activa la producción de colágeno.",
      session: "",
      time: "",
    },
    {
      title: "FACIAL PURIFICANTE",
      desc: "Tratamiento para purificar tu rostro\n\nBeneficios: Especial para pieles grasas dejando un sensación fresca e hidratada en el rostro. Purifica y controla el PH, regula el exceso de secreción sebácea en la piel. Regenerador cutáneo y excelente bactericida. Propiedades altamente hidratantes, antioxidantes, astringentes y antiinflamatorias.\n\nSUAVE AROMA: Balsámico y a maracuyá.\n\nSENSACIÓN: Fresca e hidratante.\n\nCombinado con ultrasonido facial que atenúa las manchas de la piel y líneas de expresión, reafirma el rostro, desinflama la piel, elimina toxinas y activa la producción de colágeno.",
      session: "",
      time: "",
    },
    {
      title: "FACIAL SENSI-UVA",
      desc: "Tratamiento facial antioxidante e hidratante.\n\nBeneficios: Promueve la regeneración celular; alivia y calma la irritación y rojez en pieles hipersensibles causadas por exposición prolongada al sol, acné o extracción facial. Refuerza los capilares sanguíneos mejorando la piel con cuperosis. Propiedades hidratantes y altamente antioxidantes.\n\nPara todo tipo de piel, en especial pieles sensibles y reactivas.\n\nSUAVE AROMA: A Uva. SENSACIÓN: Fresca y calmante.\n\nCombinado con ultrasonido facial que atenúa las manchas de la piel y líneas de expresión, reafirma el rostro, desinflama la piel, elimina toxinas y activa la producción de colágeno.",
      session: "",
      time: "",
    },
    {
      title: "FACIAL ACNÉ CLEAR EXPERT",
      desc: "Tratamiento que combatirá la proliferación de bacterias, una solución de la A a la Z para problemas de la piel con acné.\n\nBeneficios: Tratamiento para piel resistente al acné con aceites esenciales de cítricos.\n\nLimpieza de poros, control de sebo, reparación de la piel y equilibrio de humedad.\n\nCuidado total para espinillas, sebo, pápulas, etc.",
      session: "",
      time: "Tiempo estimado del procedimiento: 50 min",
    },
    {
      title: "FACIAL DEEP CLEANSER",
      desc: "Tratamiento de limpieza facial profunda en 4 pasos\n\nDEEP CLEANSER: Ayuda a remover el maquillaje y limpia los poros obstruidos mientras hidrata tu piel, dejando una sensación de suavidad.\nPEELING GEL: Exfolia y prepara la piel para una mejor absorción de los nutrientes. Suaviza la piel gracias a la eliminación de células muertas de la piel.\nFOAM CLEANSER: Elimina las impurezas y el exceso de grasa en los poros con una espuma abundante. Sus ingredientes Beta glucano y Phellinus Linteus ayudan a la hidratación y a tener una piel viva y brillante.\nPEEL – OFF MASK: Limpia los poros dando elasticidad y firmeza de la piel. Al momento de poner la mascarilla los residuos de la piel se absorben y se eliminan cuando la mascarilla se seca creando una barrera para proteger la piel. Los poros que se abrieron momentáneamente se vuelven a cerrar y la piel se renueva, quedando suave y radiante.",
      session: "",
      time: "",
    },
    {
      title: "TRATAMIENTO HYDRA BEAUTY (OJOS Y LABIOS)",
      desc: "Tratamiento hidratante para contorno de ojos y labios.\n\nBeneficios: Mejora la textura de la piel y líneas de expresión, ayuda a prevenir la deshidratación, dejando una sensación de suavidad y tersura.\n\nCon aroma neutro y sutil. Disminuye notoriamente líneas de expresión y arrugas. Sus ingredientes evitan la deshidratación celular.\n\n Combinado con ultrasonido en contorno de ojos que atenúa líneas de expresión, disminuye ojeras, desinflama la piel y elimina toxinas.",
      session: "",
      time: "",
    },
  ],
};
