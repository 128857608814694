import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/Hydrafacial.png";
import imgp from "Pages/Images-Tecnology/hydrafacial-pic.png";
import maquina from "Pages/Images-Tecnology/hydrafacial-machine.png";

const Hydrafacial = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" />
          </Col>
          <Col
            xs={12}
            style={{
              marginTop: "1em",
            }}
          >
            <div
              style={{
                width: "60%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="inline-list"
            >
              <h5>LA MEJOR PIEL DE TU VIDA</h5>
              <ul>
                <li>LÍNEAS FINAS &amp; ARRUGAS </li>
                <li>ELASTICIDAD &amp; FIRMEZA</li>
                <li>TONO UNIFORME &amp; VITALIDAD</li>
                <li>TEXTURA DE LA PIEL</li>
                <li>MANCHAS MARRONES</li>
                <li>PIEL GRASA</li>
                <li>POROS DILATADOS</li>
              </ul>
            </div>
          </Col>
          <Row className="inner-tech-text">
            <Col className="inner-tech-col" xs={{ order: 2, size: 12 }} md={{order: 1, size: 4}}>
              <h5>
                <b>LIMPIEZA + EXFOLIACIÓN</b>
              </h5>
              <p>Exfoliación suave y rejuvenecimiento relajante.</p>
              <h5>
                <b>EXTRACCIÓN + HIDRATACIÓN</b>
              </h5>
              <p>
                Elimina la suciedad de los poros y nutre con humectantes
                intensos.
              </p>
              <h5>
                <b>FUSIÓN + PROTECCIÓN</b>
              </h5>
              <p>Maximiza el brillo de tu piel con antioxidantes y péptidos.</p>
              <img src={imgp} alt="Fotografia de caso" />
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{order: 2, size: 4}}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col className="inner-tech-col" xs={{ order: 3, size: 12 }} md={{order: 3, size: 4}}>
              <h4
                style={{
                  marginBottom: "2em",
                }}
              >
                BOOSTS
              </h4>
              <h5>
                <b className="check-left">DERMABUILDER BOOST</b>
              </h5>
              <p>
                Minimiza los signos del envejecimiento y recarga la piel con una
                combinación de péptidos.
              </p>
              <h5>
                <b className="check-left">GROWTH FACTOR BOOST</b>
              </h5>
              <p>
                Minimiza la apariencia de líneas finas y arrugas para restaurar
                la salud y vitalidad de la piel.
              </p>
              <h5>
                <b className="check-left">BRITENOL BOOST</b>
              </h5>
              <p>
                Minimiza la aparición de manchas oscuras y manchas solares para
                un cutis uniforme.
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default Hydrafacial;
