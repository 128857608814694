import React, { useState } from "react";
import { Link } from "react-router-dom";

import "Landing/landing.css";
import { Row, Col } from "reactstrap";
import ReactSlidy from "react-slidy";

import "react-slidy/lib/styles.css";

import background_image from "Landing/Images/background_landing.jpg";
import branding from "Landing/Images/lavie-ngro.png";
import reinventate from "Landing/Images/reinventate.png";

import { Controller, Scene } from "react-scrollmagic";
import { Tween, Timeline } from "react-gsap";

import LogoReveal from "Landing/LogoReveal";

const createStyles = (isActive) => ({
  display: "inline-block",
  background: "transparent",
  border: 0,
  color: "#84C2D0",
  cursor: "pointer",
  fontSize: "50px",
});

const conditionalClass = (isActive) =>
  isActive ? "active-bullet" : "non-active-bullet";

const SLIDES = [
  ["MEDICINA ESTÉTICA", "/medicina-estetica"],
  ["THERAPY", "/therapy"],
  ["TECHNOLOGY", "/technology"],
];

const Hero = () => {
  const [actualSlide, setActualSlide] = useState(0);

  const updateSlide = ({ currentSlide }) => {
    setActualSlide(currentSlide);
  };

  return (
    <div
      className="hero"
      style={{
        backgroundImage: `url(${background_image})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
      }}
    >
      <div
        className="loading-animation"
        style={{
          backgroundImage: `url(${background_image})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          minHeight: "100vh",
        }}
      >
        <LogoReveal />
      </div>
      <Controller>
        <Scene duration="200%" triggerHook="onEnter">
          <Timeline wrapper={<div className="parallax" />}>
            <Tween
              position="0"
              from={{
                yPercent: 0,
              }}
              to={{
                yPercent: 10,
              }}
            >
              <Row className="branding-mobile" style={{ paddingTop: "10vh" }}>
                <Col xs={12}>
                  <Link to="/">
                    <img
                      src={branding}
                      style={{ margin: "auto", display: "block" }}
                      className="branding-logo"
                      alt="Logotipo"
                    ></img>
                  </Link>
                </Col>
              </Row>
              <Row
                className="padding-bottom-mobile"
                style={{ marginTop: "2rem", paddingBottom: "3em" }}
              >
                <Col xs={12}>
                  <div
                    className="container-mobile"
                    style={{
                      paddingLeft: "30vw",
                      paddingRight: "30vw",
                    }}
                  >
                    <div className="Dots">
                      {SLIDES.map((_, index) => {
                        return (
                          <button
                            key={`buttons-dots-${index}`}
                            className={conditionalClass(index === actualSlide)}
                            style={createStyles(index === actualSlide)}
                            onClick={() => updateSlide({ currentSlide: index })}
                          >
                            &bull;
                          </button>
                        );
                      })}
                    </div>
                    <ReactSlidy
                      doAfterSlide={updateSlide}
                      slide={actualSlide}
                      showArrows={false}
                    >
                      {SLIDES.map((src, index) => (
                        <Link to={src[1]} key={`slide-link-${index}`}>
                          <h4
                            className="text-secundario"
                            style={{
                              letterSpacing: "3px",
                              fontWeight: "300",
                            }}
                          >
                            {src[0]}
                          </h4>
                        </Link>
                      ))}
                    </ReactSlidy>
                  </div>
                </Col>
                <div className="scroll-banner">
                  <img src={reinventate} alt="Reinventate" />
                </div>
              </Row>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </div>
  );
};

export default Hero;
