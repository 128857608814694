import "Pages/pages.css";

import { Row, Col } from "reactstrap";

import logo from "Pages/Images-Tecnology/VENUS.png";
import imgp from "Pages/Images-Tecnology/vversa-pic.png";
import maquina from "Pages/Images-Tecnology/vversa-machine.png";

const VenusVersa = (props) => {
  return (
    <div className="full-wh">
      <div className="background-section no-opacity" />
      <div
        className="tech-padding-top-mobile"
        style={{
          position: "relative",
          zIndex: "4",
          paddingTop: "4em",
        }}
      >
        <Row>
          <Col xs={12} className="logo-container-tech">
            <img src={logo} alt="Logo para AdipoShape" />
          </Col>
          <Col
            xs={12}
            style={{
              marginTop: "1em",
            }}
          >
            <div
              style={{
                width: "60%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <h5>AUTÉNTICA VERSATILIDAD</h5>
              <p>
                Venus Versa™ combina tres tecnologías únicas y muy eficaces en
                un único dispositivo que ofrece auténtica versatilidad.
              </p>
            </div>
          </Col>
          <Row className="inner-tech-text">
            <Col className="inner-tech-col" xs={{ order: 2, size: 12 }} md={{order: 1, size: 4}}>
              <h5 style={{ marginBottom: "1em" }}>
                <b>LUZ PULSADA INTENSA (IPL) CON SMARTPULSE™</b>
              </h5>
              <p style={{ marginBottom: "2em" }}>
                Fotorejuvenecimiento / fotofacial y tratamiento contra el acné.
                SmartPulse™ garantiza la aplicación precisa y constante de
                energía al objetivo elegido con cada pulso.
              </p>
              <img src={imgp} alt="Fotografia de caso" />
            </Col>
            <Col xs={{ order: 1, size: 12 }} md={{order: 2, size: 4}}>
              <img
                src={maquina}
                className="maquina-tech"
                alt="Maquina AdipoShape"
              />
              <div className="linear-background-white" />
            </Col>
            <Col className="inner-tech-col" xs={{ order: 3, size: 12 }} md={{order: 3, size: 4}}>
              <h5
                style={{
                  marginBottom: "1em",
                }}
              >
                <b>
                  TECNOLOGÍA (MP)<sup>2</sup>
                </b>
              </h5>
              <p>
                Remodelación corporal, reducción de celulitis, tensado de piel y
                reducción de arrugas.
              </p>
              <h5
                style={{
                  marginBottom: "1em",
                }}
              >
                <b>RADIOFRECUENCIA (RF) FRACCIONADA CON SMARTSCAN™</b>
              </h5>
              <p>
                Mejora la apariencia de las afecciones en la textura de la piel,
                causadas por las cicatrices por acné u otros padecimientos, así
                como por rosácea, textura irregular, poros dilatados, discromía
                y pigmentación.
              </p>
            </Col>
          </Row>
        </Row>
      </div>
    </div>
  );
};

export default VenusVersa;
