import React, { useState, useEffect } from "react";
import branding from "Landing/Images/lavie-bco.png";

import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";

import whatsapp from "Landing/Images/whatsapp.png";
import facebook from "Landing/Images/facebook.png";
import instagram from "Landing/Images/instagram.png";
import tiktok from "Landing/Images/tiktok.png";
import youtube from "Landing/Images/youtube.png";
import pinterest from "Landing/Images/pinterest.png";
import botonayuda from "Landing/Images/botonayuda.png";

import background from "Landing/Images/background-negro.jpg";

var ReactGA = require("react-ga");

const MobileNavBar = (props) => {
  const [scroll, setScroll] = useState(false);
  const [menuOpened, setMenu] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const { innerHeight } = getWindowSize()
      setScroll(window.scrollY >= innerHeight );
    });
  }, []);


  const handleMenu = () => {
    let oldValue = menuOpened;
    setMenu(!oldValue);
  };
  return (
    <>
      <div
        className={
          scroll || menuOpened
            ? "mobile-navbar-fixed opacity-1 show-mobile"
            : "opacity-1 show-mobile"
        }
        style={{
          width: "100%",
          backgroundColor: "#3d4254",
          backgroundImage: `url(${background})`,
          position: "fixed",
          top: "0",
          left: "0",
        }}
      >
        <nav className="navbar-mobile-inner">
          <a href="/" className="nav-logo">
            <img src={branding} alt="logo" width="30px" />
          </a>
          <div
            className={menuOpened ? "hamburger active" : "hamburger"}
            onClick={handleMenu}
          >
            <span className="bar"></span>
            <span className="bar"></span>
            <span className="bar"></span>
          </div>
        </nav>
      </div>
      <div
        className={
          menuOpened
            ? "bottom-navbar-contents active show-mobile"
            : "bottom-navbar-contents show-mobile"
        }
        style={{
          backgroundImage: `url(${background})`,
        }}
      >
        <div
          style={{
            paddingLeft: "10vw",
            paddingRight: "5vw",
            marginLeft: "0",
            marginRight: "0",
          }}
          className="bottomBar"
        >
          <Row className="row-bar">
            <Col
              xs={12}
              md={2}
              style={{
                padding: "10px",
              }}
              className="text-align-center align-self-center"
            >
              <Link to="/">
                <img
                  src={branding}
                  alt="Branding"
                  style={{
                    verticalAlign: "middle",
                    maxWidth: "100px",
                  }}
                />
              </Link>
            </Col>
            <Col xs={12} md={2}>
              <Row className="f-h">
                <Col xs={12} className="align-self-end">
                  <Link to="/medicina-estetica" onClick={handleMenu}>Medicina Estética</Link>
                </Col>
                <Col xs={12} className="align-self-start">
                  {/* <Link to="/beauty-lounge">Beauty Lounge</Link> */}
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2}>
              <Row className="f-h">
                <Col xs={12} className="align-self-end">
                  <Link to="/therapy" onClick={handleMenu}>Therapy</Link>
                </Col>
                <Col xs={12} className="align-self-start">
                  <Link to="/technology" onClick={handleMenu}>Tecnología</Link>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={2}>
              <Row className="f-h">
                <Col xs={12} className="align-self-end">
                  <Link to="/contact" onClick={handleMenu}>Contacto</Link>
                </Col>
                <Col xs={12} className="mini-logos align-self-start">
                <ReactGA.OutboundLink
                      eventLabel="Instagram"
                      to="https://www.instagram.com/lavieclinicmx/"
                      target="_blank"
                      trackerNames={["Links"]}
                    >
                      <img src={instagram} alt="Logo Instagram" />
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                      eventLabel="Facebook"
                      to="https://www.facebook.com/LaVieClinicMx"
                      target="_blank"
                      trackerNames={["Links"]}
                    >
                      <img src={facebook} alt="Logo Facebook" />
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                    eventLabel="TikTok"
                    to="https://www.tiktok.com/@lavieclinicmx"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={tiktok} alt="Logo TikTok" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Youtube"
                    to="https://www.youtube.com/@lavieclinicsatelite"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={youtube} alt="Logo Youtube" />
                  </ReactGA.OutboundLink>
                  <ReactGA.OutboundLink
                    eventLabel="Pinterest"
                    to="https://www.pinterest.com/LaVieClinicMX/"
                    target="_blank"
                    trackerNames={["Links"]}
                  >
                    <img src={pinterest} alt="Logo Pinterest" />
                  </ReactGA.OutboundLink>
                </Col>
              </Row>
            </Col>
            <Col
              xs={6}
              md={2}
              className="align-self-center"
              style={{
                textAlign: "right",
              }}
            >
              <ReactGA.OutboundLink
                eventLabel="Whatsapp"
                to="https://api.whatsapp.com/send?phone=5215629993831&text=%C2%A1Hola!%20%C2%A1Me%20interesa%20saber%20acerca%20de%20La%20Vie%20Clinic!"
                target="_blank"
                trackerNames={["Links"]}
              >
                <img
                  src={botonayuda}
                  alt="Boton ayuda"
                  style={{
                    maxWidth: "100px",
                  }}
                />
              </ReactGA.OutboundLink>
            </Col>
            <Col
              xs={6}
              md={1}
              className="align-self-center"
              style={{
                textAlign: "left",
              }}
            >
              <ReactGA.OutboundLink
                eventLabel="Whatsapp"
                to="https://api.whatsapp.com/send?phone=5215629993831&text=%C2%A1Hola!%20%C2%A1Me%20interesa%20saber%20acerca%20de%20La%20Vie%20Clinic!"
                target="_blank"
                style={{
                  paddingLeft: "1em",
                }}
                trackerNames={["Links"]}
              >
                <img
                  src={whatsapp}
                  alt="Logo Whatsapp"
                  style={{
                    maxWidth: "50px",
                    cursor: "pointer",
                  }}
                />
              </ReactGA.OutboundLink>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default MobileNavBar;
