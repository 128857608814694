import img1 from "Pages/Images-Clinico/img1.jpg";
import img2 from "Pages/Images-Clinico/img2.jpg";
import img3 from "Pages/Images-Clinico/img3.jpg";
import img4 from "Pages/Images-Clinico/img4.jpg";
import img5 from "Pages/Images-Clinico/img5.jpg";
import img6 from "Pages/Images-Clinico/img6.jpg";
// import img7 from "Pages/Images-Clinico/img7.jpg";
// import img8 from "Pages/Images-Clinico/img8.jpg";
import img9 from "Pages/Images-Clinico/img9.jpg";
import img10 from "Pages/Images-Clinico/img10.jpg";
import img11 from "Pages/Images-Clinico/img11.jpg";
// import img12 from "Pages/Images-Clinico/img12.jpg";
// import img13 from "Pages/Images-Clinico/img13.jpg";
import img14 from "Pages/Images-Clinico/img14.jpg";
import img15 from "Pages/Images-Clinico/img15.jpg";
import img16 from "Pages/Images-Clinico/img16.jpg";
import img17 from "Pages/Images-Clinico/img17.jpg";
import img18 from "Pages/Images-Clinico/img18.jpg";
import img19 from "Pages/Images-Clinico/img19.jpg";
import img20 from "Pages/Images-Clinico/img20.jpg";
import img21 from "Pages/Images-Clinico/img21.jpg";
import img22 from "Pages/Images-Clinico/img22.jpg";
import img23 from "Pages/Images-Clinico/img23.jpg";
import img24 from "Pages/Images-Clinico/img24.jpg";
import img25 from "Pages/Images-Clinico/img25.jpg";
import img26 from "Pages/Images-Clinico/img26.jpg";
import img27 from "Pages/Images-Clinico/img27.jpg";
import img28 from "Pages/Images-Clinico/img28.jpg";
import img29 from "Pages/Images-Clinico/img29.jpg";
import img30 from "Pages/Images-Clinico/img30.jpg";

var images = [
  [
    img1,
    img2,
    img3,
    img14,
    img15,
    img4,
    img30,
    img5,
    img6,
    img11,
  ],
  [
    img16,
    img17,
    img29,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24
  ],
  [
    img10,
    img25,
    img9,
    img26,
    img27,
    img28
  ]
];

export default images;