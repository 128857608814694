import "Pages/pages.css";
import { Helmet } from "react-helmet";
import { Accordion } from "Pages/Partials/Accordion";
import { data } from "data/TherapyData";

import { Row, Container, Col } from "reactstrap";

import tiempo from "Landing/Images/tiempo.png";
import images from "Pages/ImagesTherapy";

import background from "Pages/Images-Therapy/background.jpg";

const MedicinaEstetica = (props) => {
  return (
    <Container
      id="section-md"
      className="Section-MD"
      style={{ minHeight: "100vh" }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>La Vie Clinic Therapy</title>
        <meta
          name="description"
          content="Dale a tu cuerpo la oportunidad de experimentar el más puro
          estado de relajación, consiente a tus músculos mientras te
          deshaces de lo que tu organismo no necesita."
        />
      </Helmet>
      <div
        className="background-section"
        style={{
          backgroundImage: `url(${background})`,
        }}
      />
      <div className="tiempo-container">
        <img src={tiempo} alt="Top banner" />
      </div>
      <Row style={{ minHeight: "100vh", paddingTop: "25vh" }}>
        <Col xs={12} style={{ paddingRight: "10vw", paddingLeft: "10vw" }}>
          <Row>
            <Col xs={12}>
              <h5>¡Reinvéntate con la plenitud del descanso!</h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5>
                Dale a tu cuerpo la oportunidad de experimentar el más puro
                estado de relajación, consiente a tus músculos mientras te
                deshaces de lo que tu organismo no necesita.
              </h5>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h5>¡Regálate un respiro!</h5>
            </Col>
          </Row>
          <Row
            style={{
              paddingTop: "2em",
              paddingBottom: "2em",
            }}
          >
            <Col xs={12}>
              <Accordion open={0}>
                {data.data.map((item, index) => {
                  return (
                    <Accordion.Item key={index}>
                      <Accordion.Header>{item.title}</Accordion.Header>
                      <Accordion.Body>
                        <Row
                          style={{
                            whiteSpace: "normal",
                          }}
                        >
                          <Col
                            md={4}
                            style={{
                              backgroundImage: `url(${images[index]})`,
                              backgroundSize: "cover",
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              marginTop: "-1em",
                              marginBottom: "-1em",
                              marginLeft: "-.25em",
                            }}
                            className="image-container-accordion"
                          />
                          <Col md={8}>
                            <Row>
                              <Col xs={12}>
                                <div>
                                  <p style={{ whiteSpace: "pre-wrap" }}>{item.desc}</p>
                                </div>
                              </Col>
                              <Col xs={12}>
                                <ul style={{textAlign: "left"}}>
                                  {
                                    item.bulletpoints?.map((bullet, index) => {
                                      return (
                                        <li>{bullet}</li>
                                      );
                                    })
                                  }
                                </ul>
                              </Col>
                              <Col xs={12}>
                                <h6>
                                  <b>{item.session}</b>
                                </h6>
                              </Col>
                              <Col xs={12}>
                                <h6>
                                  <b>{item.time}</b>
                                </h6>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}
              </Accordion>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default MedicinaEstetica;
