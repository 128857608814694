import img1 from "Pages/Images-Corporal/img1.jpg";
//import img2 from "Pages/Images-Corporal/img2.jpg";
import img3 from "Pages/Images-Corporal/img3.jpg";
import img4 from "Pages/Images-Corporal/img4.jpg";
import img5 from "Pages/Images-Corporal/img5.jpg";
import img6 from "Pages/Images-Corporal/img6.jpg";
import img7 from "Pages/Images-Corporal/img7.jpg";
import img8 from "Pages/Images-Corporal/img8.jpg";
import img9 from "Pages/Images-Corporal/img9.jpg";
import img10 from "Pages/Images-Corporal/img10.jpg";
import img11 from "Pages/Images-Corporal/img11.jpg";
import img12 from "Pages/Images-Corporal/img12.jpg";
import img13 from "Pages/Images-Corporal/img13.jpg";
//import img14 from "Pages/Images-Corporal/img14.jpg";
import img15 from "Pages/Images-Corporal/img15.jpg";
import img16 from "Pages/Images-Corporal/img16.jpg";
import img17 from "Pages/Images-Corporal/img17.jpg";
import img18 from "Pages/Images-Corporal/img18.jpg";
import img19 from "Pages/Images-Corporal/img19.jpg";
//import img20 from "Pages/Images-Corporal/img20.jpg";
import img21 from "Pages/Images-Corporal/img21.jpg";
import img22 from "Pages/Images-Corporal/img22.jpg";
import img23 from "Pages/Images-Corporal/img23.jpg";
import img24 from "Pages/Images-Corporal/img24.jpg";
import img25 from "Pages/Images-Corporal/img25.jpg";
//import img26 from "Pages/Images-Corporal/img26.jpg";
import img27 from "Pages/Images-Corporal/img27.webp";
import img28 from "Pages/Images-Corporal/img28.jpg";
import img29 from "Pages/Images-Corporal/img29.jpg";
import img30 from "Pages/Images-Corporal/img30.jpg";

// eslint-disable-next-line
export default [
  [img1, img21, img3],
  [img4, img5, img6, img7, img8, img1, img21],
  [img9, img10, img11, img12],
  [img13],
  [img15, img16, img17, img18, img25],
  [img19],
  [img23],
  [img24],
  [img21, img27, img28, img29, img30],
  [img22],
];
